import React from "react";
import { useNavigate } from "react-router-dom";
import guaranteeImage from "../assets/secure.png";

const GuaranteeHomepageBanner = () => {
  const navigate = useNavigate();

  return (
    <div className="relative p-6 md:p-5 rounded-lg shadow-sm flex flex-col md:flex-row items-center justify-between gap-8 md:gap-10 overflow-hidden">
      {/* Left Section with Gradient Background */}
      <div className="relative w-full md:w-2/3 p-8 md:p-16 text-white flex flex-col justify-center bg-gradient-to-r from-teal-700 to-teal-500 rounded-lg md:rounded-l-lg md:rounded-r-none">
        <h2 className="text-2xl md:text-4xl font-semibold mb-4">
          Guaranteed Protection for Your Items
        </h2>
        {/* <p className="text-base md:text-lg mb-6">
          With our simple guarantee plan,{" "}
          <span className="font-semibold">15%</span> of your rental earnings is
          deducted to provide coverage of up to{" "}
          <span className="font-semibold">€2,500</span>. This straightforward
          plan makes it easy to protect your assets and lend with confidence.
        </p> */}
        <p className="text-base md:text-lg mb-6">
          Our guarantee covers <span className="font-bold">every</span> rental
          up to <span className="font-bold">€2,500</span> so you can list your
          items worry free
        </p>
        <div className="flex w-full justify-center">
          <button
            className="bg-white text-sm text-teal-600 font-semibold py-3 px-6 rounded-lg hover:font-bold hover:bg-gray-100 transition ease-in-out duration-300 md:w-1/2 lg:w-1/3"
            onClick={() => navigate("/guarantee")}
          >
            Learn More
          </button>
        </div>
      </div>

      {/* Slanted Divider */}
      <div className="hidden md:block absolute top-0 bottom-0 left-2/3 w-[3%] transform -translate-x-1/2 rotate-12 bg-white"></div>

      {/* Right Section with White Background */}
      <div className="w-full md:w-1/3 flex items-center justify-center bg-white p-4 md:p-8 rounded-lg md:rounded-r-lg md:rounded-l-none">
        <img
          src={guaranteeImage}
          alt="Guaranteed Protection"
          className="w-56 md:w-72"
        />
      </div>
    </div>
  );
};

export default GuaranteeHomepageBanner;
