import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "../hooks/useAuthUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faList,
  faClipboardList,
  faCalendarCheck,
  faBuilding,
  faCheckCircle,
  faTimesCircle,
  faFileAlt,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import Users from "../components/Admin/Users/Users";
import Listings from "../components/Admin/Listings/Listings";
// Sidebar items with labels and FontAwesome icons
const sidebarItems = [
  { label: "Users", icon: faUser },
  { label: "Listings", icon: faList },
  { label: "Rental requests", icon: faClipboardList },
  { label: "Bookings", icon: faCalendarCheck },
  { label: "Rentals", icon: faBuilding },
  { label: "Completed rentals", icon: faCheckCircle },
  { label: "Cancellations", icon: faTimesCircle },
  { label: "Claims", icon: faFileAlt },
  { label: "Failed transfers", icon: faExclamationTriangle },
];

const Admin = () => {
  const navigate = useNavigate();
  const { authUser } = useAuthUser();
  const [selectedItem, setSelectedItem] = useState(sidebarItems[0].label);

  // Protect route: only allow access if the user's UID is in the admin list.
  useEffect(() => {
    const adminUids = [
      "7mVlW1E3hxUojqfqfikgROpM5N32",
      "hkrNj2LAnUO823mr0UyWoXSXZFz2",
    ];
    if (!authUser || !adminUids.includes(authUser.uid)) {
      console.log("User is not authorized as an admin.");
      navigate("/");
    }
  }, [authUser, navigate]);

  // Render the main content based on the selected sidebar option
  const renderContent = () => {
    switch (selectedItem) {
      case "Users":
        return <Users />;
      case "Listings":
        return <Listings />;
      case "Rental requests":
        return <div>Rental requests component placeholder</div>;
      case "Bookings":
        return <div>Bookings component placeholder</div>;
      case "Rentals":
        return <div>Rentals component placeholder</div>;
      case "Completed rentals":
        return <div>Completed rentals component placeholder</div>;
      case "Cancellations":
        return <div>Cancellations component placeholder</div>;
      case "Claims":
        return <div>Claims component placeholder</div>;
      case "Failed transfers":
        return <div>Failed transfers component placeholder</div>;
      default:
        return <div>Select an option from the sidebar.</div>;
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-100 font-sans">
      {/* Sidebar */}
      <aside className="w-64 bg-gray-300 text-gray-700 p-6">
        <h2 className="mb-6 text-xl font-semibold">Admin Panel</h2>
        <ul className="space-y-2">
          {sidebarItems.map((item) => (
            <li
              key={item.label}
              onClick={() => setSelectedItem(item.label)}
              className={`flex items-center px-4 py-2 rounded cursor-pointer transition-colors ${
                selectedItem === item.label
                  ? "bg-gray-400"
                  : "hover:bg-gray-400"
              }`}
            >
              <FontAwesomeIcon icon={item.icon} className="mr-3" />
              <span>{item.label}</span>
            </li>
          ))}
        </ul>
      </aside>

      {/* Main Content */}
      <main className="flex-1 p-8">{renderContent()}</main>
    </div>
  );
};

export default Admin;
