import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faArrowLeft,
  faHashtag,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import firestoreTimestampToDate from "../utils/firestoreTimestampToDate";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase.config";
import Spinner from "../components/Spinner";
import "../css/DatePickerCustomStyles.css";

const DateSelectionPage = () => {
  const navigate = useNavigate();
  const { listingId } = useParams();
  const location = useLocation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Get window width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // If listing isn't provided in state, fetch it
  useEffect(() => {
    const fetchListing = async () => {
      try {
        // If listing came through location state, use that
        if (location.state?.listing) {
          setListing(location.state.listing);
          setLoading(false);
          return;
        }

        // Otherwise fetch from Firestore
        const docRef = doc(db, "listings", listingId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setListing({ id: docSnap.id, ...docSnap.data() });
        } else {
          toast.error("Listing not found");
          navigate(-1);
        }
      } catch (error) {
        console.error("Error fetching listing:", error);
        toast.error("Failed to load listing details");
      }
      setLoading(false);
    };

    fetchListing();
  }, [listingId, location.state, navigate]);

  // Function to get excluded dates based on bookings
  const getExcludedDates = () => {
    if (!listing || !listing.bookings) return [];

    const excludedDates = [];
    listing.bookings.forEach((booking) => {
      const start = new Date(firestoreTimestampToDate(booking.startDate));
      const end = new Date(firestoreTimestampToDate(booking.endDate));
      for (
        let day = new Date(start);
        day <= end;
        day.setDate(day.getDate() + 1)
      ) {
        excludedDates.push(new Date(day));
      }
    });
    return excludedDates;
  };

  // Function to check if the selected range overlaps with booked dates
  const isRangeValid = (start, end) => {
    if (!listing || !listing.bookings) return true;

    for (const booking of listing.bookings) {
      const bookedStart = new Date(firestoreTimestampToDate(booking.startDate));
      const bookedEnd = new Date(firestoreTimestampToDate(booking.endDate));

      // Check if the selected range overlaps with any booking range
      if (
        (start >= bookedStart && start <= bookedEnd) ||
        (end >= bookedStart && end <= bookedEnd) ||
        (start <= bookedStart && end >= bookedEnd)
      ) {
        return false; // Overlap detected
      }
    }
    return true; // No overlap
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;

    // Only set the dates if they do not overlap with existing bookings
    if (start && end && isRangeValid(start, end)) {
      setStartDate(start);
      setEndDate(end);
    } else {
      // If invalid, clear the selection or just set the start date
      if (start && end && !isRangeValid(start, end)) {
        toast.error(
          "The selected date range overlaps with an existing booking. Please select a different range."
        );
      }
      setStartDate(start);
      setEndDate(null); // Clear end date to prevent invalid range
    }
  };

  const handleContinue = () => {
    if (!startDate || !endDate) {
      toast.error(
        "Please select a valid date range before continuing. If you are selecting one day, you need to select this date twice."
      );
      return;
    }

    navigate("/check-availability", {
      state: { startDate, endDate, listing },
    });
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const calculateDaysBetweenDates = () => {
    if (!startDate || !endDate) return 0;
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays + 1;
  };

  if (loading) {
    return <Spinner />;
  }

  // Determine how many months to show based on screen width
  const monthsToShow = windowWidth < 768 ? 1 : 2;

  return (
    <div className="container mx-auto px-2 md:px-4 md:py-6">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md px-2">
        <h1 className="md:text-xl text-center font-semibold text-gray-700 mt-3 md:mt-0 mb-3 header__center">
          Select Booking Dates
        </h1>

        {/* Listing info */}
        {listing && (
          <div className="mb-6 p-4 bg-gray-50 rounded-lg shadow flex flex-col items-center gap-2">
            <div>
              <img
                src={listing.imageURLs[0]}
                alt={listing.title}
                className="h-24 md:h-36 object-cover rounded-lg mb-2"
              />
            </div>
            <div className="text-center">
              <h2 className="text-base md:text-lg text-gray-700 font-semibold mb-2">
                {listing.title}
              </h2>
              <p className="text-xs md:text-sm text-gray-600">
                {listing.formattedAddress}
              </p>{" "}
            </div>
          </div>
        )}

        {/* Calendar section */}
        <div className="flex justify-center my-6">
          <DatePicker
            selected={startDate}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            minDate={new Date()}
            excludeDates={getExcludedDates()}
            inline
            monthsShown={monthsToShow}
          />
        </div>

        {/* Selected dates info */}
        {startDate && endDate && (
          <div className="mt-6 mb-8 p-4 bg-gray-100 rounded-lg shadow-md">
            <div className="flex gap-2 items-center mb-2 text-sm md:text-base">
              <FontAwesomeIcon
                icon={faCalendarCheck}
                className="text-teal-600"
              />
              <span className="font-semibold">Selected dates:</span>
              <span>
                {startDate.toLocaleDateString()} -{" "}
                {endDate.toLocaleDateString()}
              </span>
            </div>
            <div className="flex gap-2 items-center text-sm md:text-base">
              <FontAwesomeIcon icon={faHashtag} className="text-teal-600" />
              <span className="font-semibold">Number of days:</span>
              <span>{calculateDaysBetweenDates()}</span>
            </div>
          </div>
        )}

        {/* Action buttons */}
        <div className="flex justify-end gap-4 pb-4">
          <button onClick={handleGoBack} className="btn-back px-5 py-2">
            Back
          </button>
          <button
            onClick={handleContinue}
            className={
              !startDate || !endDate
                ? "btn-disabled px-5 py-2"
                : "btn-continue px-5 py-2"
            }
            disabled={!startDate || !endDate}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default DateSelectionPage;
