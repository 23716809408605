import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faMapMarkerAlt,
  faTags,
  faMoneyBillWave,
  faTrashAlt,
  faPowerOff,
} from "@fortawesome/free-solid-svg-icons";
import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../../firebase.config";
import profilePicturePlaceholder from "../../../assets/profilePicture.png";
import formatCurrency from "../../../utils/formatCurrency";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const ListingDetails = ({ listing, onActionComplete }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Format dates
  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    return new Date(
      timestamp.seconds ? timestamp.seconds * 1000 : timestamp
    ).toLocaleDateString();
  };

  // Handle image navigation
  const nextImage = () => {
    if (!listing.imageURLs || listing.imageURLs.length <= 1) return;
    setCurrentImageIndex((prev) =>
      prev === listing.imageURLs.length - 1 ? 0 : prev + 1
    );
  };

  const prevImage = () => {
    if (!listing.imageURLs || listing.imageURLs.length <= 1) return;
    setCurrentImageIndex((prev) =>
      prev === 0 ? listing.imageURLs.length - 1 : prev - 1
    );
  };

  // Change active status of listing
  const handleChangeActiveStatus = async () => {
    if (!listing.id) return;

    if (!window.confirm("Are you sure you want to change the active status?")) {
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const listingRef = doc(db, "listings", listing.id);
      await updateDoc(listingRef, {
        isActive: !listing.isActive,
      });

      // Update the local state or notify parent component
      if (onActionComplete) {
        onActionComplete(listing.isActive ? "active" : "inactive", listing.id);
      }

      toast.success(
        `Listing status updated to ${listing.isActive ? "active" : "inactive"}.`
      );
    } catch (err) {
      console.error("Error updating listing:", err);
      setError("Failed to update listing. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Delete listing
  const handleDeleteListing = async () => {
    if (!listing.id) return;

    if (
      !window.confirm(
        "Are you sure you want to delete this listing? This action cannot be undone."
      )
    ) {
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const listingRef = doc(db, "listings", listing.id);
      await deleteDoc(listingRef);

      // Notify parent component
      if (onActionComplete) {
        onActionComplete("deleted", listing.id);
      }

      alert("Listing has been deleted successfully.");
    } catch (err) {
      console.error("Error deleting listing:", err);
      setError("Failed to delete listing. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      {/* Header Section */}
      <div className="p-6 border-b">
        <div className="flex justify-between items-center">
          <h3 className="text-2xl font-semibold text-gray-800">
            {listing.title}
          </h3>
          <h4>
            <span className="font-semibold">Listing ID: </span>
            {listing.id}
          </h4>
          <div className="flex items-center">
            {listing.isActive ? (
              <span className="bg-green-100 text-green-800 px-3 py-1 rounded-full text-sm font-medium flex items-center">
                <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                Active
              </span>
            ) : (
              <span className="bg-red-100 text-red-800 px-3 py-1 rounded-full text-sm font-medium flex items-center">
                <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
                Inactive
              </span>
            )}
          </div>
        </div>
      </div>

      {/* Image Gallery Section */}
      <div className="relative h-48 md:h-64 bg-gray-100">
        {listing.imageURLs && listing.imageURLs.length > 0 ? (
          <>
            <img
              src={listing.imageURLs[currentImageIndex]}
              alt={`${listing.title} - ${currentImageIndex + 1}`}
              className="w-full h-full object-contain"
            />
            {listing.imageURLs.length > 1 && (
              <div className="absolute inset-0 flex items-center justify-between px-4">
                <button
                  onClick={prevImage}
                  className="bg-black bg-opacity-50 text-white rounded-full p-2 hover:bg-opacity-70 transition-opacity"
                >
                  &#10094;
                </button>
                <button
                  onClick={nextImage}
                  className="bg-black bg-opacity-50 text-white rounded-full p-2 hover:bg-opacity-70 transition-opacity"
                >
                  &#10095;
                </button>
              </div>
            )}
            <div className="absolute bottom-4 left-0 right-0 flex justify-center gap-2">
              {listing.imageURLs.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentImageIndex(index)}
                  className={`w-2 h-2 rounded-full ${
                    index === currentImageIndex
                      ? "bg-white"
                      : "bg-white bg-opacity-50"
                  }`}
                ></button>
              ))}
            </div>
          </>
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <img
              src={profilePicturePlaceholder}
              alt="not available"
              className="w-32 h-32 opacity-50"
            />
          </div>
        )}
      </div>

      {/* Thumbnail preview */}
      {listing.imageURLs && listing.imageURLs.length > 1 && (
        <div className="flex overflow-x-auto p-2 bg-gray-50 gap-2">
          {listing.imageURLs.map((url, index) => (
            <img
              key={index}
              src={url}
              alt={`Thumbnail ${index + 1}`}
              className={`w-16 h-16 object-cover cursor-pointer ${
                index === currentImageIndex
                  ? "border-2 border-teal-500"
                  : "border border-gray-200"
              }`}
              onClick={() => setCurrentImageIndex(index)}
            />
          ))}
        </div>
      )}

      {/* Details Section */}
      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Pricing Information */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="font-medium text-gray-700 mb-3 flex items-center">
              <FontAwesomeIcon
                icon={faMoneyBillWave}
                className="mr-2 text-teal-600"
              />
              Pricing Information
            </h4>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span className="text-gray-600">Daily Rate:</span>
                <span className="font-medium">
                  €{formatCurrency(listing.dailyRateCents)}
                </span>
              </div>
              {listing.threeDayCostCents && (
                <div className="flex justify-between">
                  <span className="text-gray-600">3-Day Cost:</span>
                  <span className="font-medium">
                    €{formatCurrency(listing.threeDayCostCents)}
                  </span>
                </div>
              )}
              {listing.sevenDayCostCents && (
                <div className="flex justify-between">
                  <span className="text-gray-600">7-Day Cost:</span>
                  <span className="font-medium">
                    €{formatCurrency(listing.sevenDayCostCents)}
                  </span>
                </div>
              )}
            </div>
          </div>

          {/* Category Information */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="font-medium text-gray-700 mb-3 flex items-center">
              <FontAwesomeIcon icon={faTags} className="mr-2 text-teal-600" />
              Category Information
            </h4>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span className="text-gray-600">Main Category:</span>
                <span className="font-medium">{listing.category || "N/A"}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Subcategory:</span>
                <span className="font-medium">
                  {listing.subcategory || "N/A"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Created:</span>
                <span className="font-medium">
                  {formatDate(listing.createdAt)}
                </span>
              </div>
            </div>
          </div>

          {/* Description Section */}
          <div className="col-span-1 md:col-span-2 bg-gray-50 p-4 rounded-lg">
            <h4 className="font-medium text-gray-700 mb-3">Description</h4>
            <p className="text-gray-600 whitespace-pre-line">
              {listing.description || "No description provided."}
            </p>
          </div>

          {/* Owner Information */}
          <div className="col-span-1 md:col-span-2 bg-gray-50 p-4 rounded-lg">
            <h4 className="font-medium text-gray-700 mb-3">Lender</h4>
            <p className="text-gray-600">
              Lender Uid:{" "}
              <span
                onClick={() => navigate(`/users/${listing.lenderUid}`)}
                className="cursor-pointer hover:font-semibold hover:underline"
              >
                {listing.lenderUid || "N/A"}
              </span>
            </p>
          </div>

          {/* Location Section */}
          <div className="col-span-1 md:col-span-2 bg-gray-50 p-4 rounded-lg">
            <h4 className="font-medium text-gray-700 mb-3 flex items-center">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className="mr-2 text-teal-600"
              />
              Location
            </h4>
            <p className="text-gray-600">
              {listing.formattedAddress || "No address provided."}
            </p>
          </div>

          {/* Actions Section - NEW */}
          <div className="col-span-1 md:col-span-2 bg-gray-50 p-4 rounded-lg">
            <h4 className="font-medium text-gray-700 mb-3">Actions</h4>

            {error && <p className="text-red-500 mb-4">{error}</p>}

            <div className="flex flex-wrap gap-4">
              <button
                onClick={handleChangeActiveStatus}
                disabled={isLoading}
                className={`flex items-center px-4 py-2 rounded-md transition-colors ${
                  listing.isActive
                    ? "btn-delete px-5 py-2"
                    : "btn-continue px-5 py-2"
                }`}
              >
                <FontAwesomeIcon
                  icon={listing.isActive ? faPowerOff : faCheckCircle}
                  className="mr-2"
                />
                {listing.isActive ? "Make Inactive" : "Make Active"}
              </button>

              <button
                onClick={handleDeleteListing}
                disabled={isLoading}
                className="flex items-center btn-delete px-5 py-2 transition-colors"
              >
                <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
                Delete Listing
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingDetails;
