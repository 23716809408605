import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons";
import profilePicturePlaceholder from "../../../assets/profilePicture.png";

const UserDetails = ({ user, navigate }) => {
  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h3 className="text-xl font-medium mb-4">User Details</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex items-center">
          <img
            src={user.profilePictureURL || profilePicturePlaceholder}
            alt={user.firstName}
            className="w-20 h-20 rounded-full mr-4"
          />
          <div>
            <h4 className="text-lg font-semibold">
              {user.firstName} {user.lastName}
            </h4>
            <p className="text-gray-600">@{user.username || "N/A"}</p>
          </div>
        </div>
        <div className="flex flex-col items-start">
          {user.emailVerified ? (
            <span className="text-green-500 flex items-center">
              <FontAwesomeIcon icon={faCheckCircle} className="mr-2" /> Email
              Verified
            </span>
          ) : (
            <span className="text-red-500 flex items-center">
              <FontAwesomeIcon icon={faTimesCircle} className="mr-2" /> Email
              Unverified
            </span>
          )}
          {user.veriff?.decision === "approved" ? (
            <span className="text-green-500 flex items-center">
              <FontAwesomeIcon icon={faShieldAlt} className="mr-2" /> ID
              Verified
            </span>
          ) : (
            <span className="text-red-500 flex items-center">
              <FontAwesomeIcon icon={faTimesCircle} className="mr-2" /> ID
              Unverified
            </span>
          )}
        </div>
        <div className="col-span-1">
          <p>
            <strong>Account Created:</strong>{" "}
            {user.createdAt
              ? new Date(user.createdAt.seconds * 1000).toLocaleString()
              : "N/A"}
          </p>
        </div>
        <div className="col-span-2">
          <p>
            <strong>About:</strong> {user.about || "No description provided."}
          </p>
        </div>
        <div className="col-span-1">
          <p>
            <strong>Email:</strong> {user.email}
          </p>
          <p>
            <strong>Phone:</strong> {user.phoneNumber || "N/A"}
          </p>
          <p>
            <strong>Address:</strong> {user.formattedAddress || "N/A"}
          </p>
        </div>
        <div className="col-span-1">
          <p>
            <strong>Bookings:</strong> {user.bookingCount || 0}
          </p>
          <p>
            <strong>Rentals:</strong> {user.rentalCount || 0}
          </p>
          <p>
            <strong>Cancellations:</strong> {user.cancellationCount || 0}
          </p>
        </div>
        <div className="col-span-1">
          <p>
            <strong>Review Count:</strong> {user.reviewCount || 0}
          </p>
          <p>
            <strong>Average Rating:</strong> {user.averageReviewRating || "N/A"}
          </p>
        </div>
        <div className="col-span-1">
          <p>
            <strong>Listings:</strong>{" "}
            {user.ownedListings && user.ownedListings.length > 0 ? (
              <ul className="list-disc ml-5">
                {user.ownedListings.map((listingId) => (
                  <li
                    key={listingId}
                    onClick={() => navigate(`/listings/${listingId}`)}
                    className="text-blue-600 hover:cursor-pointer hover:font-semibold"
                  >
                    {listingId}
                  </li>
                ))}
              </ul>
            ) : (
              "None"
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
