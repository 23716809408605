import React, { useState } from "react";
import { convertToTimeZone } from "date-fns-timezone";
import { format } from "date-fns";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase.config";
import LenderGuaranteeModal from "./LenderGuaranteeModal";
import { toast } from "react-toastify";
import formatCurrency from "../../utils/formatCurrency";
import firestoreTimestampToDate from "../../utils/firestoreTimestampToDate";

const AvailabilityConfirmedLender = ({
  message,
  onOpenModal,
  rentalRequestChatId,
}) => {
  const [isGuaranteeModalOpen, setIsGuaranteeModalOpen] = useState(false);
  const [selectedGuaranteePackage, setSelectedGuaranteePackage] = useState(
    message.guaranteePackage || { name: "None" }
  ); // Initialize with current guarantee package or 'None'

  const {
    listingTitle,
    startDate,
    endDate,
    numberOfDays,
    listingImage,
    rentalCostCents,
  } = message;

  let depositCents;
  if (message.depositCents) {
    depositCents = message.depositCents;
  }

  // Using the converted Date objects from above
  const formattedStartDate =
    firestoreTimestampToDate(startDate).toLocaleDateString();
  const formattedEndDate =
    firestoreTimestampToDate(endDate).toLocaleDateString();

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";
    const date = timestamp.toDate();
    const dublinDate = convertToTimeZone(date, { timeZone: "Europe/Dublin" });
    return format(dublinDate, "MMM dd, yyyy HH:mm");
  };

  // Open/Close guarantee modal
  const openGuaranteeModal = () => setIsGuaranteeModalOpen(true);
  const closeGuaranteeModal = () => setIsGuaranteeModalOpen(false);

  const updateGuaranteePackage = async (pkg) => {
    try {
      const messageRef = doc(
        db,
        "rental-requests",
        rentalRequestChatId,
        "messages",
        message.id
      );
      await updateDoc(messageRef, {
        guaranteePackage: pkg,
      });

      const rentalRequestRef = doc(db, "rental-requests", rentalRequestChatId);
      await updateDoc(rentalRequestRef, {
        guaranteePackage: pkg,
      });
      setSelectedGuaranteePackage(pkg);
      closeGuaranteeModal();
      toast.success("Updated guarantee package choice", { autoClose: 4000 });
    } catch (error) {
      console.error("Failed to update guarantee package: ", error);
    }
  };

  const handleConfirmGuaranteePackage = (pkg) => {
    updateGuaranteePackage(pkg);
  };

  return (
    <div className="">
      <p className="text-center font-bold my-3 text-emerald-500 md:text-lg">
        Availability Confirmed
      </p>
      {/* <p className="text-center my-5 new-line">{content}</p> */}

      <div className="flex flex-col items-center my-3">
        <img src={listingImage} alt="listing" className="w-36 rounded-md" />
        <p className="font-bold mt-2 text-sm md:text-base">{listingTitle}</p>
      </div>

      <div className="flex flex-col items-center gap-2">
        {/* <p className="text-sm md:text-base">
          <span className="font-bold">Your earnings:</span> €
          {formatCurrency(rentalCostCents - selectedGuaranteePackage.feeCents)}
        </p> */}
        <p className="text-sm md:text-base">
          <span className="font-bold">Your earnings:</span> €
          {formatCurrency(rentalCostCents * 0.85)}
        </p>
        <p className="text-sm md:text-base">
          <span className="font-semibold">Duration:</span> {numberOfDays}{" "}
          {numberOfDays === 1 ? "day" : "days"}
        </p>
        <p className="text-sm md:text-base text-center">{`${
          formattedStartDate === formattedEndDate
            ? formattedStartDate
            : `${formattedStartDate} - ${formattedEndDate}`
        }`}</p>
        {depositCents > 0 && (
          <p className="text-sm md:text-base">
            <span className="font-bold">Required deposit:</span> €
            {formatCurrency(depositCents)}
          </p>
        )}
      </div>

      {/* Display Current Guarantee Package */}
      {/* <div className="flex flex-col items-center mt-3 mb-5 border-2 pl-1 pr-1 pt-3 pb-4 bg-slate-600 rounded-lg">
        <p className="font-semibold text-sm md:text-base text-center">
          Current Guarantee Package
        </p>
        {selectedGuaranteePackage.name === "None" ? (
          <p>None</p>
        ) : (
          <div className="flex flex-col text-center text-sm">
            <div className="my-1">
              <span className="font-semibold">
                {selectedGuaranteePackage.name} Package
              </span>{" "}
              - €{formatCurrency(selectedGuaranteePackage.feeCents)} fee
            </div>
            <div>
              <span className="font-semibold">Coverage </span>- up to €
              {formatCurrency(selectedGuaranteePackage.maxCoverageCents)}
            </div>
          </div>
        )}
        <button
          className="mt-2 text-sm md:text-base btn-edit text-black py-1 px-4"
          onClick={openGuaranteeModal}
        >
          Change Package
        </button>
      </div> */}

      <p className="text-center text-xs md:text-sm text-orange-300 my-3">
        Do not hand over the item until the rental is confirmed.
      </p>

      <div className="flex flex-col items-center align-center justify-center mt-3 mb-2 gap-3">
        <button
          className="px-4 py-2 text-sm md:text-base btn-delete"
          onClick={() => onOpenModal()}
        >
          Retract Confirmation
        </button>
      </div>

      <div>
        <p className="text-xs text-gray-100 text-right">
          {formatTimestamp(message.createdAt)}
        </p>
      </div>

      {/* Guarantee Modal */}
      <LenderGuaranteeModal
        isOpen={isGuaranteeModalOpen}
        onClose={closeGuaranteeModal}
        handleConfirm={handleConfirmGuaranteePackage}
        rentalRequest={message}
        setSelectedPackage={setSelectedGuaranteePackage}
        defaultSelectedPackage={selectedGuaranteePackage}
      />
    </div>
  );
};

export default AvailabilityConfirmedLender;
