import React from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FaX } from "react-icons/fa6";

const LenderCheckListModal = ({ isOpen, onClose, handleConfirm }) => {
  const customStyles = {
    content: {
      inset: "50% auto auto 50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      padding: "16px",
      maxWidth: "600px",
      width: "90%",
      border: "none",
      boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Checklist Modal"
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="flex justify-between mb-4">
        <h2 className="text-left text-gray-700 font-bold text-lg">
          Lender Checklist
        </h2>
        <button onClick={() => onClose()}>
          <FaX />
        </button>
      </div>
      <div className="flex flex-col gap-3 mb-5">
        <p className="text-sm md:text-base text-gray-700">
          <FontAwesomeIcon icon={faCheck} /> Confirm pickup and delivery times
          with the renter.
        </p>
        <p className="text-sm md:text-base text-gray-700">
          <FontAwesomeIcon icon={faCheck} /> Take photos of your item before and
          after the rental for added security.
        </p>
        <p className="text-sm md:text-base text-gray-700">
          <FontAwesomeIcon icon={faCheck} /> If your listing requires a deposit,
          make sure the renter is aware of this and your preferred mode of
          handover (cash, bank transfer etc.)
        </p>
      </div>

      <div>
        <div className="flex items-center align-center justify-end mt-3 mb-2 gap-3 text-white">
          <button
            onClick={() => onClose()}
            className="p-2 btn-delete text-sm md:text-base"
          >
            <FontAwesomeIcon icon={faCancel} /> Go back
          </button>
          <button className="p-2 btn-confirm" onClick={() => handleConfirm()}>
            <p className="text-sm md:text-base">
              {" "}
              <FontAwesomeIcon icon={faCheck} /> Yes, proceed
            </p>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LenderCheckListModal;
