const profanityList = [
  "a55",
  "a55hole",
  "ahole",
  "anal",
  "analprobe",
  "anilingus",
  "anus",
  "assbang",
  "assbanged",
  "assbangs",
  "assfuck",
  "assfucker",
  "assh0le",
  "asshat",
  "assho1e",
  "ass hole",
  "asshole",
  "assholes",
  "assmaster",
  "assmunch",
  "asswipe",
  "asswipes",
  "b1tch",
  "bastard",
  "bastards",
  "biatch",
  "bigtits",
  "big tits",
  "bimbo",
  "bitch",
  "bitched",
  "bitches",
  "bitchy",
  "blow job",
  "bl0wj0b",
  "bl0wjb",
  "blowjob",
  "blowjobs",
  "bollock",
  "bollocks",
  "bollok",
  "boner",
  "boners",
  "boob",
  "boobies",
  "boobs",
  "booby",
  "breasts",
  "bukkake",
  "bullshit",
  "bull shit",
  "bullshits",
  "bullshitted",
  "bullturds",
  "butt",
  "butt fuck",
  "buttfuck",
  "buttfucker",
  "buttfucker",
  "buttplug",
  "c.0.c.k",
  "c.o.c.k.",
  "c.u.n.t",
  "c0ck",
  "c-0-c-k",
  "chinc",
  "chincs",
  "chink",
  "chink",
  "cl1t",
  "climax",
  "clitoris",
  "clitorus",
  "clits",
  "clitty",
  "cocain",
  "cocaine",
  "cock",
  "c-o-c-k",
  "cockblock",
  "cockholster",
  "cockknocker",
  "cocks",
  "cocksmoker",
  "cocksucker",
  "cock sucker",
  "coons",
  "corksucker",
  "crackwhore",
  "cumming",
  "cumshot",
  "cumshots",
  "cumslut",
  "cumstain",
  "cunilingus",
  "cunnilingus",
  "cunny",
  "cunt",
  "c-u-n-t",
  "cuntface",
  "cunthunter",
  "cuntlick",
  "cuntlicker",
  "cunts",
  "d0uch3",
  "d0uche",
  "d1ck",
  "dildo",
  "d1ld0",
  "d1ldo",
  "dawgie-style",
  "dick",
  "dickbag",
  "dickdipper",
  "dickface",
  "dickflipper",
  "dickhead",
  "dickheads",
  "dickish",
  "dick-ish",
  "dickripper",
  "dicksipper",
  "dickweed",
  "dickwhipper",
  "dickzipper",
  "dike",
  "dildo",
  "dildos",
  "diligaf",
  "dillweed",
  "dimwit",
  "doggie-style",
  "doggy-style",
  "douch3",
  "douche",
  "douchebag",
  "douchebags",
  "douchey",
  "dumass",
  "dumbass",
  "dumbasses",
  "dyke",
  "dykes",
  "ejaculate",
  "erection",
  "erotic",
  "essohbee",
  "extacy",
  "extasy",
  "f.u.c.k",
  "fack",
  "fag",
  "fagg",
  "fagged",
  "faggit",
  "faggot",
  "fagot",
  "fags",
  "faigt",
  "fannybandit",
  "fartknocker",
  "fisty",
  "floozy",
  "foreskin",
  "frigga",
  "fuck",
  "f-u-c-k",
  "fuckass",
  "fucked",
  "fucked",
  "fucker",
  "fuckface",
  "fuckin",
  "fucking",
  "fucknugget",
  "fucknut",
  "fuckoff",
  "fucks",
  "fucktard",
  "fuck-tard",
  "fuckup",
  "fuckwad",
  "fuckwit",
  "fudgepacker",
  "fuk",
  "fvck",
  "fxck",
  "ganja",
  "gay",
  "gays",
  "gigolo",
  "godamn",
  "godamnit",
  "goddam",
  "goddammit",
  "goddamn",
  "goldenshower",
  "gook",
  "g00k",
  "gooks",
  "gringo",
  "gr1ngo",
  "gr1ng0",
  "gspot",
  "g-spot",
  "gtfo",
  "h0m0",
  "h0mo",
  "handjob",
  "heroin",
  "herpes",
  "herpy",
  "hitler",
  "hobag",
  "hom0",
  "homo",
  "homoey",
  "honky",
  "hooker",
  "hootch",
  "hooter",
  "hooters",
  "horny",
  "hussy",
  "hymen",
  "inbred",
  "incest",
  "injun",
  "j3rk0ff",
  "jackass",
  "jackhole",
  "jackoff",
  "jerk",
  "jerk0ff",
  "jerked",
  "jerkoff",
  "junkie",
  "junky",
  "kkk",
  "knobend",
  "lesbians",
  "lesbo",
  "lesbos",
  "lezbian",
  "lezbians",
  "lezbo",
  "lezbos",
  "lezzie",
  "lezzies",
  "masterbate",
  "masterbating",
  "masterbation",
  "masturbate",
  "masturbating",
  "masturbation",
  "meth",
  "m-fucking",
  "molest",
  "moron",
  "motherfucka",
  "motherfucker",
  "motherfucking",
  "mtherfucker",
  "mthrfucker",
  "mthrfucking",
  "muffdiver",
  "muthafuckaz",
  "muthafucker",
  "mutherfucker",
  "mutherfucking",
  "muthrfucking",
  "nazi",
  "nazism",
  "negro",
  "nigga",
  "niggah",
  "niggas",
  "niggaz",
  "nigger",
  "nigger",
  "niggers",
  "niggle",
  "niglet",
  "opiate",
  "opium",
  "orgasm",
  "orgasmic",
  "orgies",
  "orgy",
  "ovary",
  "p.u.s.s.y.",
  "paki",
  "pedo",
  "pedophile",
  "pedophilia",
  "pedophiliac",
  "penis",
  "perversion",
  "pillowbiter",
  "pimp",
  "pinko",
  "piss",
  "pissed",
  "pissoff",
  "piss-off",
  "porn",
  "p0rn",
  "porno",
  "pornography",
  "prick",
  "prostitute",
  "punkass",
  "pussies",
  "pussy",
  "pussypounder",
  "queef",
  "queef",
  "queer",
  "queers",
  "quicky",
  "rape",
  "raped",
  "raper",
  "rapist",
  "rectum",
  "rectus",
  "reefer",
  "reetard",
  "retard",
  "r3tard",
  "retarded",
  "rimjob",
  "ritard",
  "rtard",
  "r-tard",
  "s.h.i.t.",
  "s.o.b.",
  "sadism",
  "sadist",
  "scantily",
  "schizo",
  "scrog",
  "scrotum",
  "scrud",
  "scum",
  "sex",
  "sexual",
  "sh1t",
  "s-h-1-t",
  "shamedame",
  "shit",
  "s-h-i-t",
  "shite",
  "shiteater",
  "shitface",
  "shithead",
  "shithole",
  "shithouse",
  "shits",
  "shitt",
  "sh1t",
  "shitted",
  "shitter",
  "shitty",
  "sissy",
  "skag",
  "skank",
  "sleaze",
  "slut",
  "slutdumper",
  "slutkiss",
  "sluts",
  "smegma",
  "snuff",
  "s-o-b",
  "sperm",
  "spooge",
  "spunk",
  "stfu",
  "stiffy",
  "t1t",
  "teabagging",
  "testee",
  "testes",
  "testicle",
  "testis",
  "tinkle",
  "titfuck",
  "titi",
  "tits",
  "tittiefucker",
  "titties",
  "titty",
  "tittyfuck",
  "tittyfucker",
  "tramp",
  "transsexual",
  "trashy",
  "tubgirl",
  "turd",
  "twat",
  "twats",
  "uterus",
  "vagina",
  "valium",
  "viagra",
  "virgin",
  "vixen",
  "wank",
  "wanker",
  "wh0re",
  "wh0reface",
  "whoralicious",
  "whore",
  "whorealicious",
  "whored",
  "whoreface",
  "whorehopper",
  "whorehouse",
  "whores",
  "whoring",
  "wigger",
  "x-rated",
];

export default profanityList;
