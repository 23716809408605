import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebase.config";
import ListingCard from "../components/ListingCard";
import notFound from "../assets/notFound.png";

// Primary categories available for navigation
const categories = [
  { label: "Film & Photography", value: "Film & Photography" },
  { label: "Electronics", value: "Electronics" },
  { label: "Musical Instruments", value: "Musical Instruments" },
  { label: "Tools & DIY", value: "Tools & DIY Equipment" },
  { label: "Event & Party Supplies", value: "Event & Party Supplies" },
  { label: "Baby & Child Gear", value: "Baby & Child Gear" },
  { label: "Fashion & Costumes", value: "Fashion & Costumes" },
  { label: "Travel & Adventure", value: "Travel & Adventure Gear" },
];

const CategoryListings = () => {
  const { categoryName } = useParams();
  const selectedCategory = categoryName ? decodeURIComponent(categoryName) : "";
  const navigate = useNavigate();

  // State for listings, pagination, and loading
  const [listings, setListings] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);

  // Define batch sizes
  const initialBatchSize = 8;
  const moreBatchSize = 16;

  // Function to fetch listings from Firestore
  const fetchListings = async (isInitial = false) => {
    setLoading(true);
    try {
      let q;
      // Use different batch sizes for initial load vs show more
      const batchSize = isInitial ? initialBatchSize : moreBatchSize;

      if (isInitial) {
        q = query(
          collection(db, "listings"),
          where("category", "==", selectedCategory),
          where("isActive", "==", true),
          orderBy("createdAt", "desc"),
          limit(batchSize)
        );
      } else {
        q = query(
          collection(db, "listings"),
          where("category", "==", selectedCategory),
          where("isActive", "==", true),
          orderBy("createdAt", "desc"),
          startAfter(lastDoc),
          limit(batchSize)
        );
      }

      const querySnapshot = await getDocs(q);
      const newListings = [];

      querySnapshot.forEach((doc) => {
        newListings.push({ id: doc.id, ...doc.data() });
      });

      // For initial load, replace listings; otherwise, append
      setListings(
        isInitial ? newListings : (prev) => [...prev, ...newListings]
      );

      // Update lastDoc if we got any results
      if (querySnapshot.docs.length > 0) {
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }

      // Check if there are more results to load
      // We have more if we received a full batch for the current operation
      setHasMore(querySnapshot.docs.length === batchSize);
    } catch (error) {
      console.error("Error fetching listings:", error);
    }
    setLoading(false);
  };

  // Fetch listings whenever the selected category changes
  useEffect(() => {
    setListings([]);
    setLastDoc(null);
    fetchListings(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  const handleShowMore = () => {
    fetchListings(false);
  };

  // Navigate to another category when selected from the sidebar
  const handleCategorySelect = (categoryValue) => {
    if (categoryValue !== selectedCategory) {
      navigate(`/category/${encodeURIComponent(categoryValue)}`);
    }
  };

  return (
    <div className="px-4 py-8">
      {/* Updated grid: on mobile, single column; on md+, aside is fixed at 200px */}
      <div className="grid grid-cols-1 md:grid-cols-[250px,1fr] gap-8">
        {/* Sidebar: Categories */}
        <aside>
          <h2 className="text-xl font-semibold mb-4 text-left text-gray-700">
            Categories
          </h2>
          <ul className="space-y-1">
            {categories.map((cat) => (
              <li
                key={cat.value}
                onClick={() => handleCategorySelect(cat.value)}
                className={`cursor-pointer text-sm md:text-base p-2 rounded text-left ${
                  cat.value === selectedCategory
                    ? "bg-teal-600 text-white font-semibold"
                    : "hover:bg-gray-200"
                }`}
              >
                {cat.label}
              </li>
            ))}
          </ul>
        </aside>

        {/* Main Content: Listings Grid */}
        <main>
          <h2 className="text-lg md:text-xl font-semibold text-gray-700 mb-6">
            Listings in "{selectedCategory}"
          </h2>
          <div className="flex w-full justify-center">
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-6 mb-5">
              {listings.length > 0
                ? listings.map((listingDetails) => (
                    <ListingCard
                      listingDetails={listingDetails}
                      key={listingDetails.id}
                    />
                  ))
                : !loading && (
                    <div className="w-full">
                      <div className="flex flex-col justify-center items-center h-64">
                        <img
                          src={notFound}
                          className="w-32 md:w-64 mb-4"
                          alt="No results found"
                        />
                        <p>No results found for "{selectedCategory}".</p>
                      </div>
                    </div>
                  )}
            </div>
          </div>
          {loading && (
            <div className="text-center my-4">
              <p>Loading...</p>
            </div>
          )}
          {hasMore && !loading && (
            <div className="flex justify-center">
              <button
                onClick={handleShowMore}
                className="px-4 btn-primary py-2"
              >
                Show More
              </button>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default CategoryListings;
