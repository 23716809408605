import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import profilePicturePlaceholder from "../../assets/profilePicture.png";
import { convertToTimeZone } from "date-fns-timezone";
import { format } from "date-fns";
import StarRating from "../StarRating";

// 1) Import Firestore methods
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase.config"; // path depends on your project

const ListingItemLenderReviews = ({ reviews }) => {
  const [reviewsAndUserData, setReviewsAndUserData] = useState([]);

  const navigate = useNavigate();

  // Convert the Firebase timestamp to a readable date
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";
    const date = timestamp.toDate();
    const dublinDate = convertToTimeZone(date, { timeZone: "Europe/Dublin" });
    return format(dublinDate, "dd-MM-yyyy");
  };

  useEffect(() => {
    // Only fetch user info if we have reviews
    if (!reviews || reviews.length === 0) {
      setReviewsAndUserData([]);
      return;
    }

    // Function to fetch user data for each review
    const fetchReviewUsers = async () => {
      // Use Promise.all to fetch in parallel
      const fetchedReviews = await Promise.all(
        reviews.map(async (review) => {
          // If the review already has reviewerUsername or reviewerProfilePictureURL,
          // you can skip fetching. Otherwise, do the doc lookup.
          if (!review.reviewerUid) {
            return review;
          }

          try {
            const userRef = doc(db, "users", review.reviewerUid);
            const userSnap = await getDoc(userRef);

            if (!userSnap.exists()) {
              // If for some reason the user doc doesn’t exist
              return {
                ...review,
                reviewerUsername: "Unknown User",
                reviewerProfilePictureURL: null,
              };
            }

            const userData = userSnap.data();
            return {
              ...review,
              reviewerUsername: userData.username || "Unknown",
              reviewerProfilePictureURL: userData.profilePictureURL || null,
            };
          } catch (error) {
            console.error("Error fetching user data:", error);
            return review; // fallback to original
          }
        })
      );

      setReviewsAndUserData(fetchedReviews);
    };

    fetchReviewUsers();
  }, [reviews]);

  return (
    <div>
      <div>
        <p className="font-semibold text-center mt-5 mb-3 md:text-lg text-gray-700">
          Recent Reviews
        </p>
      </div>

      {reviewsAndUserData.map((review, index) => (
        <div
          key={index}
          className="flex flex-col rounded-md shadow-sm border-b-2 border-gray-200 px-2 py-2"
        >
          {/* Reviewer picture, username, and date of review */}
          <div className="flex align-center items-center justify-between mb-2">
            <div className="flex items-center gap-2">
              <img
                src={
                  review.reviewerProfilePictureURL
                    ? review.reviewerProfilePictureURL
                    : profilePicturePlaceholder
                }
                alt="profilePicture"
                className="w-10 h-10 rounded-full hover:cursor-pointer"
                onClick={() => navigate(`/users/${review.reviewerUid}`)}
              />
              <div className="mb-2">
                <p
                  className="mb-2 text-gray-700 font-semibold text-xs md:text-sm hover:cursor-pointer"
                  onClick={() => navigate(`/users/${review.reviewerUid}`)}
                >
                  {review.reviewerUsername || "N/A"}
                </p>
                <StarRating rating={review.rating} />
              </div>
            </div>
            <p className="text-sm">{formatTimestamp(review.createdAt)}</p>
          </div>

          {/* Review text */}
          <p className="text-sm">{review.comment}</p>
        </div>
      ))}
    </div>
  );
};

export default ListingItemLenderReviews;
