import React from "react";
import profilePicturePlaceholder from "../../assets/profilePicture.png";
import { convertToTimeZone } from "date-fns-timezone";
import { format } from "date-fns";
import StarRating from "../StarRating";
import { BeatLoader } from "react-spinners";
import firestoreTimestampToDate from "../../utils/firestoreTimestampToDate";
import { useNavigate } from "react-router-dom";

const UserProfileReviews = ({ reviews, isFetchingReviews }) => {
  //const [reviews, setReviews] = useState([])
  const navigate = useNavigate();

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    // Convert the Firebase timestamp to a JavaScript date
    const date = timestamp.toDate();

    // Convert to the specified timezone (Europe/Dublin)
    const dublinDate = convertToTimeZone(date, { timeZone: "Europe/Dublin" });

    // Format the local date
    return format(dublinDate, "dd-MM-yyyy");
  };

  if (isFetchingReviews) {
    return (
      <div className="flex justify-center align-center items-center my-5">
        <BeatLoader color="#14b8a6" size={"20px"} />
      </div>
    );
  }

  return (
    <div>
      {reviews ? (
        reviews.map((review, index) => (
          <div
            key={index}
            className="flex flex-col bg-gray-50 rounded-md shadow-md px-2 py-2 min-w-96 mb-2"
          >
            {/* Reviewer picture and username and date of review */}
            <div className="flex align-center items-center justify-between mb-2">
              <div className="flex items-center gap-2">
                <img
                  src={
                    review.reviewerProfilePictureURL
                      ? review.reviewerProfilePictureURL
                      : profilePicturePlaceholder
                  }
                  alt="profilePicture"
                  className="w-12 h-12 rounded-full hover:cursor-pointer"
                  onClick={() => navigate(`/users/${review.reviewerUid}`)}
                ></img>
                <div className="mb-2">
                  <p
                    className="mb-2 text-gray-700 text-xs md:text-sm font-semibold hover:cursor-pointer"
                    onClick={() => navigate(`/users/${review.reviewerUid}`)}
                  >
                    {review.reviewerUsername}
                  </p>
                  <StarRating rating={review.rating} />
                </div>
              </div>
              <p className="text-sm">
                {firestoreTimestampToDate(
                  review.createdAt
                ).toLocaleDateString()}
              </p>
            </div>
            <p className="text-sm">{review.comment}</p>
          </div>
        ))
      ) : (
        <div>
          <BeatLoader />
        </div>
      )}
    </div>
  );
};

export default UserProfileReviews;
