import React from "react";
import { convertToTimeZone } from "date-fns-timezone";
import { format } from "date-fns";
import placeholderProfilePicture from "../../assets/profilePicture.png";

const StandardMessage = ({ message, authUser, userDoc, recipientDetails }) => {
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    // Convert the Firebase timestamp to a JavaScript date
    const date = timestamp.toDate();

    // Convert to the specified timezone (Europe/Dublin)
    const dublinDate = convertToTimeZone(date, { timeZone: "Europe/Dublin" });

    // Format the local date
    return format(dublinDate, "MMM dd, yyyy HH:mm");
  };

  // Determine if the message was sent by the current user
  const isSentByCurrentUser = message.senderUid === authUser.uid;

  // Get sender's profile picture and username
  const senderProfilePictureURL = isSentByCurrentUser
    ? userDoc.profilePictureURL
    : recipientDetails.profilePictureURL;

  const senderUsername = isSentByCurrentUser
    ? userDoc.username
    : recipientDetails.username;

  return (
    <div>
      <p>{message.content}</p>
      <div className="flex justify-between mt-2">
        <div className="flex gap-2 items-center">
          <img
            className="w-4 h-4 rounded-full"
            src={senderProfilePictureURL || placeholderProfilePicture}
            alt="profilePicture"
          />
          <span className="text-xs">{senderUsername}</span>
        </div>

        <p className="text-xs text-right">
          {formatTimestamp(message.createdAt)}
        </p>
      </div>
    </div>
  );
};

export default StandardMessage;
