import React, { useState, useEffect, useCallback } from "react";
// import { useAuth } from "../hooks/useAuth";
import { useAuthUser } from "../hooks/useAuthUser";
import { useUserDoc } from "../hooks/useUserDoc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faUpload,
  faSave,
  faCheckCircle,
  faXmarkCircle,
  faShieldAlt,
  faUserXmark,
  faLocationPin,
  faLock,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { db } from "../firebase.config";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  writeBatch,
  deleteDoc,
  orderBy,
  limit,
} from "firebase/firestore";
import {
  getAuth,
  deleteUser,
  signOut,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { BeatLoader } from "react-spinners";
import { useJsApiLoader } from "@react-google-maps/api";
import { toast } from "react-toastify";
import Modal from "react-modal";
import profilePicPlaceholder from "../assets/profilePicture.png";
import { FaX } from "react-icons/fa6";
import VeriffButton from "../components/VeriffButton";
import GoogleLocationSearch from "../components/GoogleLocationSearch";
import { useNavigate } from "react-router-dom";
import { checkEmailVerified } from "../utils/verificationUtils";
import UserProfileReviews from "../components/UserProfile/UserProfileReviews";
import ListingCard from "../components/ListingCard";
import firestoreTimestampToDate from "../utils/firestoreTimestampToDate";

const LIBRARIES = ["places"];

const MyProfile = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    about: "",
    phoneNumber: "",
    emailVerified: false,
    profilePictureURL: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [deletingAccount, setDeletingAccount] = useState(false);
  const [currentUsername, setCurrentUsername] = useState("");
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [locationInputValue, setLocationInputValue] = useState(null);
  const [newLocation, setNewLocation] = useState({});
  const [newGeolocation, setNewGeolocation] = useState(null);
  const [formattedAddress, setFormattedAddress] = useState("");
  const [addressComponents, setAddressComponents] = useState({});
  const [geolocation, setGeolocation] = useState({});
  const [futureBookings, setFutureBookings] = useState([]);
  const [originalAbout, setOriginalAbout] = useState("");
  const [originalPhoneNumber, setOriginalPhoneNumber] = useState("");
  const [originalFormattedAddress, setOriginalFormattedAddress] = useState("");
  const [originalAddressComponents, setOriginalAddressComponents] = useState(
    {}
  );
  const [originalGeolocation, setOriginalGeolocation] = useState({});
  const [isLocationChanged, setIsLocationChanged] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [profilePictureURL, setProfilePictureURL] = useState("");
  const [isConfirmDeleteAccountModalOpen, setIsConfirmDeleteAccountModalOpen] =
    useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [deleteAccountPassword, setDeleteAccountPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  // Also track form fields inside the modal
  const [oldPassword, setOldPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [activeTab, setActiveTab] = useState("profile");
  const [isFetchingReviews, setIsFetchingReviews] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [userListings, setUserListings] = useState([]);

  const navigate = useNavigate();

  const { username, email, phoneNumber, about } = formData;

  const { authUser } = useAuthUser();
  const { userDoc } = useUserDoc();

  // Fetch User Listings
  useEffect(() => {
    let userListingsVariable = [];
    const fetchUserListings = async () => {
      if (!authUser) return;
      setIsLoading(true);

      const q = query(
        collection(db, "listings"),
        where("lenderUid", "==", authUser.uid)
      );
      const qSnap = await getDocs(q);
      if (qSnap.empty) {
        setIsLoading(false);
      } else {
        qSnap.forEach((listing) => {
          userListingsVariable.push({ ...listing.data(), id: listing.id });
        });

        setUserListings(userListingsVariable);
        setIsLoading(false);
      }
    };

    fetchUserListings();
  }, [authUser]);

  // Fetch user reviews
  useEffect(() => {
    const fetchReviews = async () => {
      if (!authUser) return;
      setIsFetchingReviews(true);
      let reviewsArray = [];

      try {
        const reviewsRef = collection(db, "users", authUser.uid, "reviews");
        const q = query(reviewsRef, orderBy("createdAt", "desc"), limit(10));
        const querySnapshot = await getDocs(q);

        for (let document of querySnapshot.docs) {
          const reviewData = { id: document.id, ...document.data() };
          const reviewerRef = doc(db, "users", reviewData.reviewerUid);
          const reviewerSnap = await getDoc(reviewerRef);

          if (reviewerSnap.exists()) {
            const reviewerData = reviewerSnap.data();
            reviewsArray.push({
              ...reviewData,
              reviewerUsername: reviewerData.username,
              reviewerProfilePictureURL: reviewerData.profilePictureURL,
            });
          } else {
            reviewsArray.push(reviewData);
          }
        }

        setReviews(reviewsArray);
      } catch (error) {
        console.error("Error fetching reviews: ", error);
      } finally {
        setIsFetchingReviews(false);
      }
    };

    fetchReviews();
  }, [authUser]);

  useEffect(() => {
    if (!authUser) {
      toast.info("You need to be logged in to view your profile.");
      navigate("/");
    }
  }, [authUser, navigate, userDoc]);

  useEffect(() => {
    if (!isLocationModalOpen) {
      setNewLocation({});
      setNewGeolocation(null);
      setLocationInputValue("");
      setIsLocationChanged(false); // Reset the change flag
    }
  }, [isLocationModalOpen]);

  useEffect(() => {
    const verifyEmail = async () => {
      const emailVerified = await checkEmailVerified();
      setEmailVerified(emailVerified);
    };
    verifyEmail();
  }, []);

  useEffect(() => {
    loadCloudinaryScript(() => {});
  }, []);

  // Reset new location when modal closes without confirmation
  useEffect(() => {
    if (!isLocationModalOpen) {
      setNewLocation(null);
      setNewGeolocation(null);
      setLocationInputValue("");
    }
  }, [isLocationModalOpen]);

  useEffect(() => {
    if (authUser) {
      fetchUserData();
    }
  }, [authUser]);

  // Function to check for future bookings - prevent the user from deleting their account if future bookings exist
  useEffect(() => {
    const checkForFutureBookings = async () => {
      if (authUser) {
        const bookingsRef = collection(db, `users/${authUser.uid}/bookings`);
        const rentalsRef = collection(db, `users/${authUser.uid}/rentals`);

        const bookingsSnap = await getDocs(bookingsRef);
        const rentalsSnap = await getDocs(rentalsRef);

        let allBookingsAndRentals = [];
        let futureBookingsArray = [];

        // If there are bookings
        if (!bookingsSnap.empty) {
          bookingsSnap.docs.forEach((booking) => {
            allBookingsAndRentals.push(booking.data());
          });
        }

        // If there are any rentals
        if (!rentalsSnap.empty) {
          rentalsSnap.docs.forEach((rental) => {
            allBookingsAndRentals.push(rental);
          });
        }

        const currentDate = new Date();

        futureBookingsArray = allBookingsAndRentals.filter(
          (bookingOrRental) => {
            if (!bookingOrRental.endDate) {
              return false;
            }
            const bookingOrRentalEndDate = firestoreTimestampToDate(
              bookingOrRental.endDate
            );
            return bookingOrRentalEndDate >= currentDate;
          }
        );

        if (futureBookingsArray.length > 0) {
          setFutureBookings(futureBookingsArray);
        }
      }
    };

    checkForFutureBookings();
  }, [authUser]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: LIBRARIES,
  });

  const reauthenticateUser = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      // Since only email/password sign-in is allowed, no need to check providerId
      if (!deleteAccountPassword) {
        toast.error("Please enter your password to confirm account deletion.");
        return false;
      }

      const credential = EmailAuthProvider.credential(
        user.email,
        deleteAccountPassword
      );
      await reauthenticateWithCredential(user, credential);

      return true;
    } catch (error) {
      console.error("Error re-authenticating user:", error);
      if (error.code === "auth/wrong-password") {
        toast.error("Incorrect password. Please try again.");
      } else {
        toast.error("Failed to re-authenticate. Please try again.");
      }
      return false;
    }
  };

  // Function to handle password change for user
  const handleChangePassword = async () => {
    const auth = getAuth();
    try {
      // Basic validation
      if (!oldPassword || !newPassword || !confirmNewPassword) {
        toast.error("Please fill out all password fields.");
        return;
      }
      if (newPassword !== confirmNewPassword) {
        toast.error("New passwords do not match.");
        return;
      }
      if (newPassword.length < 6) {
        toast.error("New password must be at least 6 characters.");
        return;
      }

      setIsLoading(true);

      // 1. Re-auth with old password
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(user.email, oldPassword);
      await reauthenticateWithCredential(user, credential);

      // 2. Update the password
      await updatePassword(user, newPassword);

      toast.success("Password updated successfully!");

      // 3. Close modal & reset fields
      setIsChangePasswordModalOpen(false);
      setOldPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    } catch (error) {
      console.error("Error changing password:", error);
      if (error.code === "auth/wrong-password") {
        toast.error("Current password is incorrect. Please try again.");
      } else if (error.code === "auth/weak-password") {
        toast.error("Password is too weak. Try a stronger one.");
      } else if (error.code === "auth/requires-recent-login") {
        toast.error("Please reauthenticate and try again.");
      } else {
        toast.error("Failed to change password. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const customStyles = {
    content: {
      inset: "50% auto auto 50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      padding: "16px",
      maxWidth: "600px",
      width: "90%",
      border: "none",
      boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };

  const fetchUserData = async () => {
    const userRef = doc(db, "users", authUser.uid);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      setFormData(data);
      setCurrentUsername(data.username);

      setFormattedAddress(data.formattedAddress || "");
      setAddressComponents(data.addressComponents || {});
      setGeolocation(data._geoloc || {});

      // Set original values
      setOriginalAbout(data.about || "");
      setOriginalPhoneNumber(data.phoneNumber || "");
      setOriginalFormattedAddress(data.formattedAddress || "");
      setOriginalAddressComponents(data.addressComponents || {});
      setOriginalGeolocation(data._geoloc || {});

      if (data.profilePictureURL) {
        setProfilePictureURL(data.profilePictureURL);
      }
    }
  };

  const handleLocationSelected = (locationData) => {
    const selectedFormattedAddress = locationData.formattedAddress || "";
    const selectedGeolocation = locationData.geolocation || {};

    // Update newLocation and newGeolocation
    setNewLocation({
      formattedAddress: selectedFormattedAddress,
      addressComponents: locationData.addressComponents || {},
    });
    setNewGeolocation(selectedGeolocation);

    // Check if the selected location is different from the original
    const isDifferentLocation =
      selectedFormattedAddress !== originalFormattedAddress ||
      selectedGeolocation.lat !== originalGeolocation.lat ||
      selectedGeolocation.lng !== originalGeolocation.lng;

    setIsLocationChanged(isDifferentLocation);
    setLocationInputValue(selectedFormattedAddress);
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const cloudName = "prodCloudinary";
  const uploadPreset = "kitShareUploadPreset";

  const openUploadWidget = () => {
    window.cloudinary
      .createUploadWidget(
        {
          cloudName: cloudName,
          uploadPreset: uploadPreset,
          multiple: false,
          maxFiles: 1,
          sources: ["local", "camera"],
          maxFileSize: 5500000,
          folder: "profilePictures",
          context: {
            alt: "profile_picture",
            caption: "profile picture",
          },
          resourceType: "image",
        },
        async (error, result) => {
          if (!error && result && result.event === "success") {
            setProfilePictureURL(result.info.secure_url);
            console.log("Image uploaded successfully:", result.info.secure_url);

            // Update the user's profile in Firestore
            try {
              const userDocRef = doc(db, "users", authUser.uid);
              await updateDoc(userDocRef, {
                profilePictureURL: result.info.secure_url,
              });
              toast.success("Your profile picture has been updated.");
            } catch (error) {
              console.error(
                "Error updating profile picture in Firestore:",
                error
              );
              toast.error(
                "Failed to update profile picture. Please try again."
              );
            }
          } else if (error) {
            console.error("Cloudinary upload error:", error);
            toast.error("Failed to upload profile picture. Please try again.");
          }
        }
      )
      .open();
  };

  const handleSaveChanges = async () => {
    try {
      // Check if any changes have been made
      const changes = {};

      if (about !== undefined && about !== originalAbout) {
        changes.about = about;
      }
      if (phoneNumber !== undefined && phoneNumber !== originalPhoneNumber) {
        changes.phoneNumber = phoneNumber;
      }

      if (Object.keys(changes).length === 0) {
        // No changes were made
        toast.info("No changes were made.");
        return;
      }

      setIsLoading(true);

      // Update the user's document with the changes
      const userDocRef = doc(db, "users", authUser.uid);
      await updateDoc(userDocRef, changes);

      // Update original values
      if (changes.about !== undefined) setOriginalAbout(changes.about);
      if (changes.phoneNumber !== undefined)
        setOriginalPhoneNumber(changes.phoneNumber);

      setIsLoading(false);
      toast.success("Profile changes saved successfully.");
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to save changes: " + error.message);
    }
  };

  const loadCloudinaryScript = (callback) => {
    const existingScript = document.getElementById("cloudinaryWidgetScript");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://widget.cloudinary.com/v2.0/global/all.js";
      script.id = "cloudinaryWidgetScript";
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    } else if (callback) {
      callback();
    }
  };

  // Function to update all listings with the new location
  const updateListingsLocation = async (newLocation, newGeolocation) => {
    try {
      const listingsRef = collection(db, "listings");
      const q = query(listingsRef, where("lenderUid", "==", authUser.uid));
      const querySnapshot = await getDocs(q);

      const batch = writeBatch(db);

      querySnapshot.forEach((docSnapshot) => {
        const listingDocRef = doc(db, "listings", docSnapshot.id);
        batch.update(listingDocRef, {
          addressComponents: newLocation.addressComponents,
          formattedAddress: newLocation.formattedAddress,
          _geoloc: newGeolocation,
        });
      });

      await batch.commit();
      console.log("Listings updated successfully.");
    } catch (error) {
      console.error("Error updating listings:", error);
      throw error;
    }
  };

  // Handle confirmation of new location
  const handleConfirmNewLocation = async () => {
    try {
      setIsLoading(true);
      const userDocRef = doc(db, "users", authUser.uid);
      await updateDoc(userDocRef, {
        formattedAddress: newLocation.formattedAddress,
        addressComponents: newLocation.addressComponents,
        _geoloc: newGeolocation,
      });

      // Apply updates to the component state
      setFormattedAddress(newLocation.formattedAddress);
      setAddressComponents(newLocation.addressComponents);
      setGeolocation(newGeolocation);

      setIsLocationModalOpen(false);

      await updateListingsLocation(newLocation, newGeolocation);

      toast.success("Location updated successfully.");
    } catch (error) {
      console.error("Error updating location:", error);
      toast.error("Failed to update location.");
    } finally {
      setIsLoading(false);
    }
  };

  const shareProfile = () => {
    const profileUrl = `www.kitshare.ie/users/${authUser.uid}`;
    navigator.clipboard.writeText(profileUrl);
    toast.success("Profile URL copied to clipboard!", { autoClose: 3000 });
  };

  const sendAdminNotification = async () => {
    try {
      // Code to send an email notification via SendGrid to the admin team
      console.log(
        "Admin notification sent with user data and future bookings."
      );
    } catch (error) {
      console.error("Error sending admin notification:", error);
    }
  };

  const deleteUserListings = async () => {
    try {
      const listingsRef = collection(db, "listings");
      const q = query(listingsRef, where("lenderUid", "==", authUser.uid));

      const listingsSnapshot = await getDocs(q);

      const batch = writeBatch(db);
      listingsSnapshot.forEach((listingDoc) => {
        const listingDocRef = doc(db, "listings", listingDoc.id);
        batch.delete(listingDocRef);
      });

      await batch.commit();
      console.log("User listings deleted successfully.");
    } catch (error) {
      console.error("Error deleting user listings:", error);
      throw error;
    }
  };

  // Helper function to delete all docs in a given subcollection path
  const deleteAllDocsInSubcollection = async (subcollectionPath) => {
    const subcollectionRef = collection(db, subcollectionPath);
    const snapshot = await getDocs(subcollectionRef);

    const batch = writeBatch(db);
    snapshot.forEach((docSnap) => {
      batch.delete(docSnap.ref);
    });

    await batch.commit();
  };

  const handleDeleteAccountRequest = async () => {
    try {
      setDeletingAccount(true);
      if (!authUser) {
        toast.error("User is not logged in.");
        setDeletingAccount(false);
        return;
      }

      const reauthSuccess = await reauthenticateUser();
      if (!reauthSuccess) {
        setDeletingAccount(false);
        return; // Stop the process if re-authentication fails
      }

      if (futureBookings.length > 0) {
        toast.error(
          "Unable to delete your account, you have active future bookings."
        );
        // Trigger the email notification to admin
        //sendAdminNotification(authUser, futureBookingsList);
        setDeletingAccount(false);
        return; // Exit early as we won't proceed with deletion
      }

      // Before deleting user doc, remove bookings & rentals subcollections
      await deleteAllDocsInSubcollection(`users/${authUser.uid}/bookings`);
      await deleteAllDocsInSubcollection(`users/${authUser.uid}/rentals`);

      // Delete user listings
      await deleteUserListings();

      // Proceed with account deletion
      const auth = getAuth();
      await deleteUser(auth.currentUser);
      await deleteDoc(doc(db, "users", authUser.uid));
      await signOut(auth);
      setDeletingAccount(false);
      navigate("/");
      toast.success("Account deleted successfully.");
    } catch (error) {
      console.error("Error deleting account:", error);
      toast.error("Failed to delete account. Please try again.");
      setDeletingAccount(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center mb-5 px-5 md:px-5">
      <div className="flex w-full justify-center">
        <h1 className="w-full md:w-2/3 text-center mt-5 text-xl font-bold header__center">
          My Profile
        </h1>
      </div>
      <div className="mt-5">
        <div className="relative rounded-full w-40 h-40 bg-gray-200 overflow-hidden">
          <img
            src={profilePictureURL ? profilePictureURL : profilePicPlaceholder}
            alt="ProfilePicture"
            className="object-cover w-full h-full text-center"
            // onError={(e) => (e.target.src = "path/to/your/default-avatar.jpg")} // Placeholder if the image fails to load
          />

          <button
            onClick={() => openUploadWidget()}
            className="absolute bottom-5 right-5 p-2 bg-white rounded-full text-teal-500 hover:text-teal-600"
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
        </div>
      </div>

      {authUser?.emailVerified ? (
        <p className="text-sm md:text-base p-3 border-2 my-3 rounded-md border-emerald-500 font-semibold text-gray-700">
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="text-emerald-600 text-lg"
          />{" "}
          Email verified
        </p>
      ) : (
        <p className="text-sm md:text-base p-3 border-2 my-3 rounded-md border-red-500 font-semibold text-gray-700">
          <FontAwesomeIcon icon={faXmarkCircle} /> Email unverified
        </p>
      )}

      {(userDoc?.veriff?.decision &&
        userDoc?.veriff?.decision === "approved") ||
      userDoc?.veriff?.decision === "declined" ? (
        <p className="text-sm md:text-base p-3 border-2 rounded-md border-emerald-500 font-semibold text-gray-700">
          <FontAwesomeIcon
            icon={faShieldAlt}
            className="text-emerald-600 text-lg"
          />{" "}
          ID Verified
        </p>
      ) : (
        <VeriffButton
          authUser={authUser}
          userDoc={userDoc}
          removeTooltip={true}
        />
      )}
      <div className="w-full max-w-xl">
        <hr className="my-5"></hr>

        {/* 3 buttons to showcase profile details, reviews or listings */}
        <div className="flex justify-center gap-5 mb-5">
          <button
            onClick={() => setActiveTab("profile")}
            className={`${
              activeTab === "profile"
                ? "btn-primary font-semibold"
                : "btn-secondary"
            } px-2 sm:px-3 w-1/3 py-2 text-sm md:text-base`}
          >
            Profile
          </button>
          <button
            onClick={() => setActiveTab("reviews")}
            className={`${
              activeTab === "reviews" ? "btn-primary" : "btn-secondary"
            } px-2 sm:px-3 w-1/3 text-sm md:text-base`}
          >
            Reviews
          </button>
          <button
            onClick={() => setActiveTab("listings")}
            className={`${
              activeTab === "listings" ? "btn-primary" : "btn-secondary"
            } px-2 sm:px-3 w-1/3 text-sm md:text-base`}
          >
            Listings
          </button>
        </div>

        {/* Profile Information */}
        {activeTab === "profile" && (
          <div>
            {/* Profile Details */}
            <div className="">
              <label className="block">
                <span className="text-gray-700 font-bold text-sm">
                  About You
                </span>
                <textarea
                  id="about"
                  placeholder="Add a few words about yourself.."
                  value={about}
                  onChange={onChange}
                  className="text-sm mt-1 block w-full rounded-md border-gray-400 shadow-sm outline-none focus:border-teal-600 focus:border-2"
                />
              </label>

              <label className="block mt-4">
                <span className="text-gray-700 font-bold text-sm">Email</span>
                <input
                  type="text"
                  id="email"
                  value={email}
                  disabled={true}
                  onChange={onChange}
                  className="text-sm mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                />
              </label>
              <div className="flex flex-col mt-5 shadow-sm text-sm">
                <span className="text-gray-700 font-bold text-sm">
                  Username
                </span>

                <input
                  className={"p-2 text-sm mt-1"}
                  type="text"
                  id="username"
                  value={username}
                  disabled={true}
                  placeholder="Username"
                ></input>
              </div>
              <label className="block mt-4">
                <span className="text-gray-700 font-bold text-sm">
                  Phone Number
                </span>
                <input
                  onChange={onChange}
                  id="phoneNumber"
                  type="text"
                  value={phoneNumber}
                  className="text-sm md:text-base mt-1 block w-full rounded-md border-gray-300 shadow-sm outline-none focus:border-teal-600 focus:border-2"
                />
              </label>

              {/* Save Profile Changes Button */}
              <div className="flex justify-end mb-5">
                {isLoading ? (
                  <button className="btn-continue px-4 py-2 flex items-center gap-2">
                    <BeatLoader color="white" size={8} />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn-continue flex items-center gap-2 px-4 py-2"
                    onClick={handleSaveChanges}
                  >
                    <FontAwesomeIcon icon={faSave} />
                    Save Changes
                  </button>
                )}
              </div>
            </div>

            {/* Location Section */}
            <div className="flex justify-between align-center items-center shadow-md p-4 rounded-md">
              <label className="block mt-4">
                <h2 className="text-base md:text-lg font-semibold text-gray-700 mb-3">
                  Change Location
                </h2>
                <div className="text-sm md:text-base mt-1">
                  {formattedAddress}
                </div>
              </label>

              <button
                className="btn-edit px-4 py-2"
                onClick={() => setIsLocationModalOpen(true)}
              >
                <FontAwesomeIcon icon={faLocationPin} /> Change Location
              </button>
            </div>

            {/* Profile Actions Section */}
            <section className="bg-white p-4 rounded-md shadow-md mt-6">
              <h2 className="text-base md:text-lg font-semibold text-gray-700 mb-3">
                Profile Actions
              </h2>
              <p className="text-sm text-gray-600 mb-4">
                Update your profile picture or change your password.
              </p>

              <div className="flex justify-end">
                <div className="flex flex-col gap-3">
                  {/* Update Profile Picture Button */}
                  <button
                    type="button"
                    className="btn-secondary text-sm md:text-base px-4 py-2 flex items-center gap-2 justify-center"
                    onClick={openUploadWidget}
                  >
                    <FontAwesomeIcon icon={faUpload} />
                    Update Profile Picture
                  </button>

                  <button
                    type="button"
                    className="btn-delete px-4 py-2 flex gap-2 items-center justify-center"
                    onClick={() => setIsChangePasswordModalOpen(true)}
                  >
                    <FontAwesomeIcon icon={faLock} />
                    Change Password
                  </button>

                  <button
                    type="button"
                    className="btn-primary px-4 py-2 flex gap-2 items-center justify-center"
                    onClick={() => shareProfile()}
                  >
                    <FontAwesomeIcon icon={faShareNodes} />
                    Share Profile
                  </button>
                </div>
              </div>
            </section>

            {/* Danger Zone Section */}
            <section className="bg-white p-4 rounded-md shadow-md mt-6">
              <h2 className="text-base md:text-lg font-semibold text-red-600 mb-3">
                Delete Account
              </h2>
              <p className="text-sm text-gray-600 mb-4">
                Deleting your account is irreversible. Please proceed with
                caution.
              </p>
              <div className="flex flex-wrap gap-3 justify-end">
                <button
                  type="button"
                  className="btn-delete flex px-4 py-2 items-center gap-2"
                  onClick={() => setIsConfirmDeleteAccountModalOpen(true)}
                  disabled={deletingAccount}
                >
                  <FontAwesomeIcon icon={faUserXmark} />
                  {deletingAccount ? "Deleting..." : "Delete Account"}
                </button>
              </div>
            </section>
          </div>
        )}

        {/* Reviews Section */}
        {activeTab === "reviews" && (
          <UserProfileReviews
            userUid={authUser.uid}
            reviews={reviews}
            isFetchingReviews={isFetchingReviews}
          />
        )}

        {/* Confirm Delete Account Modal */}
        <Modal
          isOpen={isConfirmDeleteAccountModalOpen}
          onRequestClose={() => setIsConfirmDeleteAccountModalOpen(false)}
          contentLabel="Delete Account"
          ariaHideApp={false}
          style={customStyles}
        >
          <div className="">
            <div className="flex justify-between ">
              <h2 className="text-lg md:text-xl text-gray-700 font-semibold mb-4">
                Delete Your Account
              </h2>
              <div
                className="hover:cursor-pointer hover:font-bold text-xl"
                onClick={() => setIsConfirmDeleteAccountModalOpen(false)}
              >
                <FaX />
              </div>
            </div>
            {futureBookings && futureBookings.length > 0 ? (
              <div>
                <p className="text-red-600 font-semibold mb-4">
                  Cannot delete your account. The following future bookings
                  exist:
                </p>
                <ul className="list-disc list-inside mb-4">
                  {futureBookings.map((booking, index) => {
                    const startDate = firestoreTimestampToDate(
                      booking.startDate
                    );
                    const endDate = firestoreTimestampToDate(booking.endDate);
                    return (
                      <li key={index}>
                        From{" "}
                        <strong>
                          {startDate.toLocaleDateString(undefined, {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </strong>{" "}
                        to{" "}
                        <strong>
                          {endDate.toLocaleDateString(undefined, {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </strong>
                      </li>
                    );
                  })}
                </ul>
                <p className="mb-4">
                  Please fulfill or cancel these bookings before deleting your
                  account.
                </p>
                <div className="flex justify-end gap-5 mt-5">
                  <button
                    onClick={() => setIsConfirmDeleteAccountModalOpen(false)}
                    className="px-3 py-2 btn-back"
                  >
                    Go Back
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <p className="mb-4 text-gray-700">
                  Deleting your account is irreversible. Please enter your
                  password to confirm.
                </p>
                <label className="block mt-4">
                  <span className="text-gray-700 font-semibold text-sm">
                    Password
                  </span>
                  <input
                    type="password"
                    id="deleteAccountPassword"
                    value={deleteAccountPassword}
                    onChange={(e) => setDeleteAccountPassword(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  />
                </label>
                <div className="mt-4 flex justify-end gap-3">
                  <button
                    onClick={() => setIsConfirmDeleteAccountModalOpen(false)}
                    className="btn-cancel px-5 py-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleDeleteAccountRequest}
                    className="btn-continue px-5 py-2"
                  >
                    {deletingAccount ? (
                      <BeatLoader color="white" />
                    ) : (
                      <div> Delete Account</div>
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal>

        {/* Change Password Modal */}
        <Modal
          isOpen={isChangePasswordModalOpen}
          onRequestClose={() => setIsChangePasswordModalOpen(false)}
          contentLabel="Change Password"
          ariaHideApp={false}
          style={customStyles}
        >
          <div className="">
            <div className="flex justify-between">
              <h2 className="text-base md:text-xl text-gray-700 font-semibold mb-4">
                Change Password
              </h2>
              <div
                className="hover:cursor-pointer hover:font-bold text-xl"
                onClick={() => setIsChangePasswordModalOpen(false)}
              >
                <FaX />
              </div>
            </div>
            <p className="text-sm md:text-base mb-4 text-gray-600">
              Please enter your current password, then choose a new password.
            </p>

            <label className="block mt-4">
              <span className="text-gray-700 font-semibold text-xs md:text-sm">
                Current Password
              </span>
              <input
                type="password"
                id="oldPassword"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                className="text-sm md:text-base mt-1 block w-full rounded-md border-gray-300 shadow-sm outline-none focus:border-teal-600"
              />
            </label>
            <label className="block mt-4">
              <span className="text-gray-700 font-semibold text-xs md:text-sm">
                New Password
              </span>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className={`${
                  newPassword === confirmNewPassword && newPassword.length > 5
                    ? "border-emerald-500"
                    : "border-gray-300"
                } text-sm md:text-base mt-1 block w-full rounded-md shadow-sm outline-none focus:border-teal-600`}
              />
            </label>
            <label className="block mt-4">
              <span className="text-gray-700 font-semibold text-xs md:text-sm">
                Confirm New Password
              </span>
              <input
                type="password"
                id="confirmNewPassword"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                className={`${
                  newPassword === confirmNewPassword &&
                  confirmNewPassword.length > 5
                    ? "border-emerald-500"
                    : "border-gray-300"
                } text-sm md:text-base mt-1 block w-full rounded-md shadow-sm outline-none focus:border-emerald-500`}
              />
            </label>

            <div className="mt-4 flex justify-end gap-3">
              <button
                onClick={() => setIsChangePasswordModalOpen(false)}
                className="text-sm md:text-base btn-cancel px-5 py-2"
              >
                Cancel
              </button>
              <button
                onClick={handleChangePassword}
                className="text-sm md:text-base btn-continue px-5 py-2"
              >
                {isLoading ? <BeatLoader color="white" /> : "Update Password"}
              </button>
            </div>
          </div>
        </Modal>

        {/* Location Change Modal */}
        <Modal
          isOpen={isLocationModalOpen}
          onRequestClose={() => setIsLocationModalOpen(false)}
          contentLabel="Change Location"
          ariaHideApp={false}
          style={customStyles}
        >
          <div>
            <div className="flex justify-between align-center items-center mb-4">
              <h2 className="text-lg md:text-xl text-gray-700 font-semibold">
                Change Your Location
              </h2>
              <div
                className="hover:cursor-pointer hover:font-bold text-lg md:text-xl"
                onClick={() => setIsLocationModalOpen(false)}
              >
                <FaX />
              </div>
            </div>
            <p className="text-sm md:text-base mb-4 text-gray-700">
              Changing your location will update the location of all your
              listings to this new location.
            </p>

            <div className="w-full">
              {isLoaded ? (
                <GoogleLocationSearch
                  onLocationSelect={handleLocationSelected}
                  // Removed onLocationChange
                  isLoaded={isLoaded}
                  inputValue={locationInputValue}
                  setInputValue={setLocationInputValue}
                />
              ) : (
                <div>Loading...</div>
              )}
            </div>

            <div className="mt-4 flex justify-end gap-3">
              <button
                onClick={() => setIsLocationModalOpen(false)}
                className="text-sm md:text-base btn-back px-5 py-2"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmNewLocation}
                className={
                  isLocationChanged
                    ? "text-sm md:text-base btn-confirm px-5 py-2"
                    : "text-sm md:text-base btn-secondary px-5 py-2"
                }
                disabled={!isLocationChanged}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      </div>
      {/* Listings Section */}
      {activeTab === "listings" && (
        <div className="flex w-full justify-center">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-6 mb-5">
            {userListings.map((userListing, index) => (
              <ListingCard listingDetails={userListing} key={index} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyProfile;
