import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faHammer,
  faGuitar,
  faBaby,
  faChampagneGlasses,
  faBagShopping,
  faCampground,
  faGamepad,
} from "@fortawesome/free-solid-svg-icons";

const categories = [
  { label: "Film & Photography", icon: faCamera, value: "Film & Photography" },
  { label: "Electronics", icon: faGamepad, value: "Electronics" },
  {
    label: "Musical Instruments",
    icon: faGuitar,
    value: "Musical Instruments",
  },
  { label: "Tools & DIY", icon: faHammer, value: "Tools & DIY Equipment" },
  {
    label: "Event & Party Supplies",
    icon: faChampagneGlasses,
    value: "Event & Party Supplies",
  },
  { label: "Baby & Child Gear", icon: faBaby, value: "Baby & Child Gear" },
  {
    label: "Fashion & Costumes",
    icon: faBagShopping,
    value: "Fashion & Costumes",
  },
  {
    label: "Travel & Adventure",
    icon: faCampground,
    value: "Travel & Adventure Gear",
  },
];

const SearchCategoryCards = () => {
  const navigate = useNavigate();

  const handleCategoryClick = (categoryValue) => {
    // Navigate to the search-results page with the category in query params.
    navigate(`/category/${encodeURIComponent(categoryValue)}`);
  };

  return (
    <div className="container mx-auto px-4 py-8 md:py-16">
      <div className="text-center mb-12">
        {/* <h2 className="text-3xl font-semibold bg-gradient-to-r from-gray-400 via-teal-400 to-teal-600 inline-block text-transparent bg-clip-text"> */}
        <h2 className="text-xl md:text-3xl font-semibold text-gray-700">
          Discover & Rent
        </h2>
        <p className="mt-4 text-sm md:text-lg text-gray-600">
          Explore our categories and find what you need.
        </p>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6 max-w-4xl mx-auto">
        {categories.map((category) => (
          <div
            key={category.value}
            onClick={() => handleCategoryClick(category.value)}
            className="cursor-pointer bg-white rounded-lg shadow-md flex flex-col items-center justify-center p-6 hover:shadow-lg transition-shadow"
          >
            <div className="text-teal-600 text-4xl mb-4">
              <FontAwesomeIcon icon={category.icon} />
            </div>
            <p className="text-xs md:text-base text-center font-semibold text-gray-700">
              {category.label}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchCategoryCards;
