import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { auth, db } from "../../firebase.config";
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  orderBy,
  onSnapshot,
  arrayUnion,
  arrayRemove,
  doc,
  updateDoc,
} from "firebase/firestore";
import { useAuthUser } from "../../hooks/useAuthUser";
import { useUserDoc } from "../../hooks/useUserDoc";
import { useNavigate } from "react-router-dom";
import AvailabilityRequestRenter from "./AvailabilityRequestRenter";
import AvailabilityRequestLender from "./AvailabilityRequestLender";
import AvailabilityConfirmedRenter from "./AvailabilityConfirmedRenter";
import AvailabilityConfirmedLender from "./AvailabilityConfirmedLender";
import RentalConfirmedRenter from "./RentalConfirmedRenter";
import RentalConfirmedLender from "./RentalConfirmedLender";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { convertToTimeZone } from "date-fns-timezone";
import { toast } from "react-toastify";
import profilePicturePlaceholder from "../../assets/profilePicture.png";
import DynamicChatModal from "./DynamicChatModal";
import { FaArrowLeftLong } from "react-icons/fa6";
import StandardMessage from "./StandardMessage";

const ChatWindow = ({
  selectedChatId,
  onDeleteChat,
  recipientDetails,
  listingTitle,
  onBack,
}) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  //const { currentUser } = useAuth();
  const { authUser } = useAuthUser();
  const { userDoc } = useUserDoc();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalConfirmAction, setModalConfirmAction] = useState(() => () => {}); // Default empty function
  const [modalConfirmText, setModalConfirmText] = useState("");
  const [modalCancelText, setModalCancelText] = useState("");
  const [modalShowInputField, setModalShowInputField] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);

  const { username, profilePictureURL } = recipientDetails;

  const navigate = useNavigate();

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    // Convert the Firebase timestamp to a JavaScript date
    const date = timestamp.toDate();

    // Convert to the specified timezone (Europe/Dublin)
    const dublinDate = convertToTimeZone(date, { timeZone: "Europe/Dublin" });

    // Format the local date
    return format(dublinDate, "MMM dd, yyyy HH:mm");
  };

  const handleOpenModal = (
    title,
    message,
    confirmAction,
    confirmText,
    cancelText,
    showInputField
  ) => {
    setModalTitle(title);
    setModalMessage(message);
    setModalConfirmAction(() => confirmAction);
    setModalConfirmText(confirmText);
    setModalCancelText(cancelText);
    setIsModalOpen(true);
    setModalShowInputField(showInputField);
  };

  // Helper function to call the firebase cloud function to delete the rental request from firebase
  const handleDeleteRentalRequest = async () => {
    const body = JSON.stringify({
      rentalRequestId: selectedChatId,
    });
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://deleterentalrequest-deleterentalrequest-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      setIsModalOpen(false);
      setIsLoading(false);
      toast.success("The rental availability request has been deleted.", {
        autoClose: 3000,
      });
      // Remove the chat from the state
      onDeleteChat(selectedChatId);
    } catch (error) {
      console.log(error.message);
      setIsModalOpen(false);
      setIsLoading(false);
      toast.error("Something went wrong deleting the rental request.", {
        autoClose: 3000,
      });
    }
  };

  // Helper function to call the firebase cloud function to send the availability request rejected email to the requestor
  const sendAutomatedAvailabilityRejectedEmail = async (reason) => {
    const body = JSON.stringify({
      reason: reason,
      recipientDetails: recipientDetails,
      lenderUsername: userDoc.username,
      listingTitle: listingTitle,
    });
    try {
      setIsLoading(true);
      // Need to requestor details (will receive the email), reason, listing details and listing lender username
      const response = await fetch(
        `https://rejectavailrequestemail-rejectavailrequestemail-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();

      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
      toast.error(
        "Something went wrong sending the availability rejection email.",
        {
          autoClose: 3000,
        }
      );
    }
  };

  // Helper function to call the firebase cloud function to send the availability retracted email to the requestor
  const sendAutomatedRetractConfirmationEmail = async (reason) => {
    const body = JSON.stringify({
      reason: reason,
      recipientDetails: recipientDetails,
      firstName: userDoc.firstName,
      listingTitle: listingTitle,
    });
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://retractavailconfirmationemail-retractavailconfirm-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();

      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
      toast.error(
        "Something went wrong sending the availability rejection email.",
        {
          autoClose: 3000,
        }
      );
    }
  };

  // Helper function to call the firebase cloud function to send the availability cancelled email to the item lender
  const sendCancelConfirmedAvailabilityRequestEmail = async (reason) => {
    const body = JSON.stringify({
      reason: reason,
      recipientDetails: recipientDetails,
      listingTitle: listingTitle,
      renterUsername: userDoc.username,
    });

    try {
      setIsLoading(true);
      const response = await fetch(
        `https://cancelavailrequestemail-cancelavailrequestemail-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();

      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
      toast.error(
        "Something went wrong sending the availability rejection email.",
        {
          autoClose: 3000,
        }
      );
    }
  };

  // Listing lender: rejecting availability request
  const handleRejectAvailabilityRequest = async (reason) => {
    try {
      // Send automated email to the requestor that the item is not available + provide reason (if supplied)
      await sendAutomatedAvailabilityRejectedEmail(reason);

      // Delete the rental-request from Firestore
      await handleDeleteRentalRequest();

      // Remove the chat from the list of chats at the sidebar
      onDeleteChat(selectedChatId);

      // showcase toast notification on successful deletion
      toast.success("Availability request rejected.", { autoClose: 3000 });
      setIsModalOpen(false);
    } catch (error) {
      toast.error("Something went wrong:", error);
    }
    setIsModalOpen(false);
  };

  // Availability requestor: cancel availability request
  // No automated email required?
  const handleCancelAvailabilityRequest = async () => {
    const body = JSON.stringify({
      rentalRequestId: selectedChatId,
    });
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://deleterentalrequest-deleterentalrequest-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();

      setIsModalOpen(false);
      setIsLoading(false);
      toast.success("Your rental availability request has been deleted.", {
        autoClose: 3000,
      });
      // Remove the chat from the state
      onDeleteChat(selectedChatId);

      // Reload the page to showcase the updated chat list
      //window.location.reload();
    } catch (error) {
      console.log(error.message);
      setIsModalOpen(false);
      setIsLoading(false);
      toast.error("Something went wrong deleting the rental request.", {
        autoClose: 3000,
      });
    }
  };

  // Item lender: retract availability confirmation
  const handleRetractConfirmation = async (reason) => {
    try {
      // Send automated email to the requestor that the item is no longer available + provide reason (if supplied)
      await sendAutomatedRetractConfirmationEmail(reason);

      // Delete the rental-request from Firestore
      await handleDeleteRentalRequest();

      // Remove the chat from the list of chats at the sidebar
      onDeleteChat(selectedChatId);

      // showcase toast notification on successful deletion
      toast.success(`Availability retracted for ${listingTitle}`, {
        autoClose: 3000,
      });
      setIsModalOpen(false);
    } catch (error) {}

    console.log("handle reject availability request", reason);
    setIsModalOpen(false);
  };

  // Requestor: cancel request before "confirming" and paying for rental (last stage)
  const handleCancelConfirmedAvailabilityRequest = async (reason) => {
    try {
      await sendCancelConfirmedAvailabilityRequestEmail(reason);

      await handleDeleteRentalRequest();

      onDeleteChat(selectedChatId);

      toast.success(`Cancelled availability request for ${listingTitle}`, {
        autoClose: 3000,
      });
      setIsModalOpen(false);
    } catch (error) {
      toast.error("Failed to cancel availability request.", {
        autoClose: 3000,
      });
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    if (selectedChatId) {
      const messagesRef = collection(
        db,
        "rental-requests",
        selectedChatId,
        "messages"
      );
      const q = query(messagesRef, orderBy("createdAt", "asc"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const messagesData = querySnapshot.docs.map((doc) => {
          const data = { id: doc.id, ...doc.data() };
          return data;
        });
        setMessages(messagesData);
      });

      return () => unsubscribe();
    }
  }, [selectedChatId]);

  const messagesContainerRef = useRef(null);

  const lastMessageRef = useRef(null);

  useEffect(() => {
    if (lastMessageRef.current && messages.length > 0) {
      requestAnimationFrame(() => {
        setTimeout(() => {
          lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
        }, 0);
      });
    }
  }, [messages]);

  // Determine the user's role with respect to the selected chat
  const isCurrentUserTheLender = (message) => {
    return message.lenderUid === authUser.uid;
  };

  const renderMessageContent = (message) => {
    // Pass islender boolean to message components to adjust their rendering
    const islender = isCurrentUserTheLender(message);

    switch (message.type) {
      case "availability-request":
        // Decide which message component to render based on whether the current user is the lender
        return islender ? (
          <AvailabilityRequestLender
            message={message}
            onOpenModal={() =>
              handleOpenModal(
                "Reject Request?",
                "Are you sure you want to reject this request?",
                handleRejectAvailabilityRequest,
                "Yes, reject request",
                "No, keep request",
                true
              )
            }
            recipientDetails={recipientDetails}
            rentalRequestChatId={selectedChatId}
            authUser={authUser}
            userDoc={userDoc}
          />
        ) : (
          <AvailabilityRequestRenter
            message={message}
            onOpenModal={() =>
              handleOpenModal(
                "Cancel Availability Request?",
                "Are you sure you want to cancel this availability request?",
                handleCancelAvailabilityRequest,
                "Yes, cancel request",
                "No, keep request",
                false
              )
            }
          />
        );
      case "availability-confirmed":
        return islender ? (
          <AvailabilityConfirmedLender
            message={message}
            rentalRequestChatId={selectedChatId}
            onOpenModal={() =>
              handleOpenModal(
                "Retract Confirmation?",
                "Are you sure you want to retract the availability confirmation?",
                handleRetractConfirmation,
                "Yes, retract confirmation",
                "No, keep confirmation",
                true
              )
            }
          />
        ) : (
          <AvailabilityConfirmedRenter
            message={message}
            rentalRequestChatId={selectedChatId}
            authUser={authUser}
            userDoc={userDoc}
            onOpenModal={() =>
              handleOpenModal(
                "Cancel Request?",
                "Are you sure you want to cancel your rental request?",
                handleCancelConfirmedAvailabilityRequest,
                "Yes, cancel request",
                "No, keep request",
                true
              )
            }
          />
        );
      case "rental-confirmed":
        return islender ? (
          <RentalConfirmedLender message={message} />
        ) : (
          <RentalConfirmedRenter message={message} />
        );

      default:
        return (
          <StandardMessage
            message={message}
            authUser={authUser}
            userDoc={userDoc}
            recipientDetails={recipientDetails}
          />
        );
    }
  };

  // Send message function
  const sendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() === "") return;

    const messageToSend = newMessage;
    setNewMessage(""); // Clear the input field immediately for a seamless user experience
    setSendingMessage(true);

    try {
      // Add the message to the 'messages' subcollection
      await addDoc(
        collection(db, "rental-requests", selectedChatId, "messages"),
        {
          content: messageToSend,
          senderUid: authUser.uid,
          createdAt: serverTimestamp(),
        }
      );

      // Update the 'unreadBy' field in the rental-request document
      const rentalRequestRef = doc(db, "rental-requests", selectedChatId);

      // Remove sender's UID from 'unreadBy'
      await updateDoc(rentalRequestRef, {
        unreadBy: arrayRemove(authUser.uid),
      });

      // Add recipient's UID to 'unreadBy'
      await updateDoc(rentalRequestRef, {
        unreadBy: arrayUnion(recipientDetails.id),
      });
    } catch (error) {
      console.error("Error sending message:", error);
      toast.error("Failed to send message.");
      setNewMessage(messageToSend); // Restore the message if sending fails
    } finally {
      setSendingMessage(false);
    }
  };

  //const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <div className="flex flex-col h-full">
      <DynamicChatModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={modalConfirmAction}
        title={modalTitle}
        message={modalMessage}
        isLoading={isLoading}
        confirmText={modalConfirmText}
        cancelText={modalCancelText}
        showInputField={modalShowInputField}
      />
      {/* Chat window heading */}
      <div className="relative flex text-gray-700 justify-center items-center bg-gradient-to-r from-gray-200 to-gray-300 shadow-sm p-2 text-center font-semibold hover:font-bold hover:cursor-pointer">
        {onBack && (
          <FaArrowLeftLong
            className="absolute top-4 left-3 text-gray-700 mr-4 cursor-pointer"
            onClick={onBack}
            size={"24px"}
          />
        )}
        <div
          className="flex justify-center items-center"
          onClick={() => navigate(`/users/${recipientDetails.id}`)}
        >
          <img
            src={
              profilePictureURL ? profilePictureURL : profilePicturePlaceholder
            }
            alt="profilePicture"
            className="w-10 h-10 rounded-full mr-2"
          />
          <p>Chat with {username}</p>
        </div>
      </div>
      {/* Messages container */}
      <div className="flex-1 overflow-y-auto p-4" ref={messagesContainerRef}>
        {messages.map((message, index) => {
          const isLastMessage = index === messages.length - 1;
          return (
            <div
              key={message.id}
              ref={isLastMessage ? lastMessageRef : null}
              className={`p-2 rounded-lg m-2 ${
                message.senderUid === authUser.uid
                  ? "w-2/3 md:w-2/5 lg:w-1/3 ml-auto bg-blue-500 text-white shadow-md"
                  : "w-2/3 md:w-2/5 lg:w-1/3 mr-auto bg-gray-500 text-white shadow-md"
              }`}
            >
              {renderMessageContent(message)}
            </div>
          );
        })}
        {/* <div ref={endOfMessagesRef} /> */}
      </div>
      {/* Input field */}
      <div className="border-t border-gray-300 bg-white md:p-3">
        <form
          className="flex px-1 pt-2 pb-0 mb-2 items-center gap-2"
          onSubmit={sendMessage}
        >
          <div className="flex w-full gap-1 md:gap-4">
            <input
              className="text-sm w-full p-2 border border-gray-300 rounded-lg outline-none focus:border-2 focus:border-teal-600"
              type="text"
              value={newMessage}
              placeholder="Add your message here..."
              onChange={(e) => setNewMessage(e.target.value)}
              // Remove the onFocus handler or update it as below
            />
            <button
              className="p-2 w-1/6 md:w-1/12 text-sm md:text-base bg-teal-600 text-white rounded-lg shadow-md font-semibold hover:bg-teal-700"
              type="submit"
            >
              <FontAwesomeIcon icon={faArrowUp} size="lg" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChatWindow;
