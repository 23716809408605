import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { db } from "../firebase.config";
import ListingCard from "../components/ListingCard";
import { BeatLoader } from "react-spinners";
import { useAuthUser } from "../hooks/useAuthUser";
import Modal from "react-modal";
import { FaX } from "react-icons/fa6";
import { toast } from "react-toastify";
import socialShare from "../assets/socialShare.png";

const MyListings = () => {
  const [listings, setListings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Local state for share-modal
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [newListingURL, setNewListingURL] = useState(null);

  const { authUser } = useAuthUser();
  const location = useLocation();
  const navigate = useNavigate();

  // Get data from location.state
  const { listingURL, showShareNewListingPopup } = location.state || {};

  useEffect(() => {
    // If there's a valid listing URL & we should show the share popup
    if (listingURL && showShareNewListingPopup) {
      setNewListingURL(listingURL);
      setIsShareModalOpen(true);
      // Optionally, you might want to "clear" location state so if the user reloads
      // they don't see the popup again. For example:
      navigate(".", { replace: true }); // navigate to the same path but reset state
    }
  }, [listingURL, showShareNewListingPopup, navigate]);

  useEffect(() => {
    const fetchUserListings = async () => {
      if (!authUser) return; // or redirect

      const userListings = [];
      const q = query(
        collection(db, "listings"),
        where("lenderUid", "==", authUser.uid),
        orderBy("createdAt", "desc")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        userListings.push({ id: doc.id, ...doc.data() });
      });

      setListings(userListings);
      setIsLoading(false);
    };

    fetchUserListings();
  }, [authUser]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      borderRadius: "2%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "rgba(255, 255, 255, 0.95)",
      width: "90%",
      maxHeight: "90vh",
      overflowY: "auto",
      maxWidth: "500px",
    },
  };

  // Basic function to copy the listing URL to clipboard
  const handleCopyLink = () => {
    if (!newListingURL) return;
    navigator.clipboard
      .writeText(newListingURL)
      .then(() => {
        toast.success("Link copied to clipboard", { autoClose: 1000 });
      })
      .catch((err) => toast.error("Could not copy link. Error:", err));
  };

  return (
    <div>
      {/* SHARE NEW LISTING MODAL */}
      <Modal
        isOpen={isShareModalOpen}
        // onRequestClose={() => setIsShareModalOpen(false)}
        contentLabel="Share New Listing"
        style={customStyles}
        ariaHideApp={false}
      >
        <div>
          <div className="flex justify-center">
            <img src={socialShare} alt="share-social" className="md:max-w-96" />
          </div>
          <div className="flex justify-between align-center items-center mb-4">
            <h2 className="md:text-lg font-semibold text-gray-700">
              Your listing is live!
            </h2>
            <div
              className="hover:font-bold hover:cursor-pointer"
              onClick={() => setIsShareModalOpen(false)}
            >
              <FaX />
            </div>
          </div>
          <p className="mb-2 text-sm md:text-base text-gray-700">
            Congrats, your listing is now live! 🎉 Boost your chances of getting
            rentals by sharing it with your community and friends on social
            media!
          </p>
          {/* {newListingURL && (
            <div className="flex gap-2 mb-4">
              <input
                type="text"
                readOnly
                value={`${newListingURL}`}
                className="text-sm md:text-base w-full text-gray-600 p-2 border border-gray-300 rounded outline-none focus:border-2 focus:border-teal-600"
              />
              <button
                onClick={handleCopyLink}
                className="btn-continue text-sm md:text-base px-3"
              >
                Copy
              </button>
            </div>
          )} */}
        </div>
      </Modal>

      {/* PAGE TITLE */}
      <div className="flex w-full justify-center">
        <h1 className="w-full md:w-2/3 text-center my-5 text-xl font-bold header__center">
          My Listings
        </h1>
      </div>

      {/* LISTINGS GRID */}
      <div className="flex justify-center w-full mt-5 px-2">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-6 mb-5">
          {isLoading ? (
            <div className="flex justify-center items-center">
              <BeatLoader color="#14b8a6" />
            </div>
          ) : (
            listings.map((listing) => (
              <ListingCard key={listing.id} listingDetails={listing} />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default MyListings;
