import React, { useEffect, useState } from "react";
import {
  getAuth,
  applyActionCode,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "react-modal";

import { toast } from "react-toastify";

const FirebaseCustomEmailActionHandler = () => {
  const [status, setStatus] = useState("verifying"); // 'verifying', 'success', 'error'
  const [message, setMessage] = useState("Processing request...");
  const [modeState, setModeState] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [showNewVerificationEmailModal, setShowNewVerificationEmailModal] =
    useState(false);
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const auth = getAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const mode = query.get("mode");
    const actionCode = query.get("oobCode");

    if (!mode || !actionCode) {
      setStatus("error");
      setMessage("Invalid or missing parameters.");
      return;
    }

    setModeState(mode);

    switch (mode) {
      case "verifyEmail":
        handleVerifyEmail(actionCode);
        break;
      case "resetPassword":
        handleVerifyPasswordResetCode(actionCode);
        break;
      default:
        setStatus("error");
        setMessage("Unsupported action mode.");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (auth.currentUser) {
      setFirstName(auth.currentUser.displayName);
      setEmail(auth.currentUser.email);
    } else {
      console.log("no user present");
    }
  }, [auth]);

  const handleVerifyEmail = async (actionCode) => {
    try {
      await applyActionCode(auth, actionCode);
      // Email verified successfully
      setStatus("success");
      setMessage("Your email has been verified successfully!");

      if (auth.currentUser) {
        // Reload current user to reflect updated emailVerified
        await auth.currentUser.reload();

        // Navigate home
        // navigate("/");
      } else {
        setMessage(
          (prevMessage) => prevMessage + " Please log in to continue."
        );
      }
    } catch (error) {
      setStatus("error");
      setMessage(
        "Failed to verify email. The link may have expired or is invalid."
      );
      console.error("Error verifying email:", error);
    }
  };

  const handleVerifyPasswordResetCode = async (actionCode) => {
    try {
      // Verify that the code is valid and get the associated email
      const email = await verifyPasswordResetCode(auth, actionCode);
      setResetEmail(email);
      setStatus("success");
      setMessage("Please enter a new password.");
      // Now show a form to set a new password
    } catch (error) {
      setStatus("error");
      setMessage("Invalid or expired password reset link.");
      console.error("Error verifying password reset code:", error);
    }
  };

  const handleResetPassword = async () => {
    // Retrieve the oobCode from the URL again
    const query = new URLSearchParams(location.search);
    const actionCode = query.get("oobCode");

    try {
      if (newPassword.trim().length < 6) {
        toast.error("Password must be at least 6 characters.");
        return;
      }

      await confirmPasswordReset(auth, actionCode, newPassword);
      toast.success("Your password has been reset successfully!");
      navigate("/sign-in");
    } catch (error) {
      console.error("Error resetting password:", error);
      toast.error(
        "Failed to reset password. The link may have expired or is invalid."
      );
    }
  };

  // Function to handle sending new verification email to user (if existing link has potentially expired)
  const handleSendNewVerificationEmail = async (e) => {
    e.preventDefault();

    if (!firstName || !email) {
      toast.error("Please complete the form fields.");
      return;
    }

    setIsLoading(true);
    const body = JSON.stringify({
      firstName,
      email,
    });

    try {
      setIsLoading(true);
      const response = await fetch(
        `https://emailverification-emailverification-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );
      const data = await response.json();
      setIsLoading(false);
      toast.success(
        "Please check your inbox for the verification email. Don't forget to check spam folder if you can't find the email in your primary inbox!",
        { autoClose: 6000 }
      );
      setShowNewVerificationEmailModal(false);
      navigate("/");
    } catch (error) {
      toast.error(
        "Failed to send verification email, please contact KitShare support at support@kitshare.ie",
        {
          autoClose: 3000,
        }
      );
      setIsLoading(false);
    }
  };

  const customStyles = {
    content: {
      inset: "50% auto auto 50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      padding: "20px",
      maxWidth: "500px",
      width: "90%",
      border: "none",
      boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-3">
      <Modal
        isOpen={showNewVerificationEmailModal}
        onRequestClose={() => setShowNewVerificationEmailModal(false)}
        contentLabel="new verification email modal"
        style={customStyles}
        ariaHideApp={false}
      >
        <div>
          <p className="text-base text-lg text-gray-700 font-semibold mb-5">
            Send new verification email
          </p>
          <form
            className="p-0 m-0"
            onSubmit={(e) => handleSendNewVerificationEmail(e)}
          >
            {/* First Name Field */}
            <div className="flex flex-col w-full mb-3">
              <label className="text-sm font-semibold mb-1 text-gray-700">
                First Name / Username
              </label>
              <input
                className="p-2 text-sm md:text-base outline-none focus:border-teal-500 focus:border-2"
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First name / Username"
              />
            </div>
            {/* Email Field */}
            <div className="flex flex-col w-full">
              <label className="text-sm font-semibold mb-1 text-gray-700">
                Email
              </label>
              <input
                className="p-2 text-sm md:text-base outline-none focus:border-teal-500 focus:border-2"
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email"
              />
            </div>

            <div className="flex justify-end mt-5">
              <button className="btn-continue px-5" disabled={isLoading}>
                {isLoading ? "Sending.." : "Send email"}
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <div className="bg-white shadow-md rounded-lg p-5 max-w-md mx-auto">
        {modeState === "verifyEmail" && (
          <>
            {status === "verifying" && (
              <div>
                <h1 className="text-2xl font-semibold mb-4 text-center">
                  Email Verification
                </h1>
                <p className="text-center">{message}</p>
              </div>
            )}
            {status === "success" && (
              <div>
                <h1 className="text-2xl font-semibold mb-4 text-center text-teal-600">
                  Email Verified!
                </h1>
                <p className="text-center mb-6">{message}</p>
                {auth.currentUser ? (
                  <button
                    onClick={() => navigate("/")}
                    className="w-full btn-primary"
                  >
                    Go to Home
                  </button>
                ) : (
                  <button
                    onClick={() => navigate("/sign-in")}
                    className="w-full btn-primary"
                  >
                    Log In
                  </button>
                )}
              </div>
            )}
            {status === "error" && (
              <div>
                <h1 className="text-2xl font-semibold mb-4 text-center text-red-600">
                  Verification Failed
                </h1>
                <p className="text-center mb-4">{message}</p>
                <button
                  onClick={() => setShowNewVerificationEmailModal(true)}
                  className="w-full btn-primary my-2"
                >
                  Send new verification email
                </button>
                <button
                  onClick={() => navigate("/")}
                  className="w-full btn-delete my-2"
                >
                  Go to Home
                </button>
                <p className="text-sm md:text-base text-center my-3">
                  If you have any issues or questions, please contact{" "}
                  <a
                    href="mailto:support@kitshare.ie"
                    className="text-blue-500 underline"
                  >
                    support@kitshare.ie
                  </a>{" "}
                  for assistance.
                </p>
              </div>
            )}
          </>
        )}

        {modeState === "resetPassword" && (
          <>
            {status === "verifying" && (
              <div>
                <h1 className="text-2xl font-semibold mb-4 text-center">
                  Reset Password
                </h1>
                <p className="text-center">{message}</p>
              </div>
            )}
            {status === "success" && (
              <div>
                <h1 className="text-2xl font-semibold mb-4 text-center text-teal-600">
                  Reset Your Password
                </h1>
                <p className="text-center mb-4">
                  {message} (For {resetEmail})
                </p>
                <div className="mb-4">
                  <input
                    type="password"
                    placeholder="Enter new password"
                    className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-teal-500"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
                <button
                  onClick={handleResetPassword}
                  className="w-full btn-primary"
                >
                  Reset Password
                </button>
              </div>
            )}
            {status === "error" && (
              <div>
                <h1 className="text-2xl font-semibold mb-4 text-center text-red-600">
                  Reset Failed
                </h1>
                <p className="text-center mb-4">{message}</p>
                <p className="text-center mb-6">
                  If you have any issues or questions, please contact{" "}
                  <a
                    href="mailto:support@kitshare.ie"
                    className="text-blue-500 underline"
                  >
                    support@kitshare.ie
                  </a>{" "}
                  for assistance.
                </p>
                <button
                  onClick={() => navigate("/")}
                  className="w-full btn-delete"
                >
                  Go to Home
                </button>
              </div>
            )}
          </>
        )}

        {modeState !== "verifyEmail" &&
          modeState !== "resetPassword" &&
          status === "error" && (
            <div>
              <h1 className="text-2xl font-semibold mb-4 text-center text-red-600">
                Action Not Supported
              </h1>
              <p className="text-center mb-4">{message}</p>
              <button
                onClick={() => navigate("/")}
                className="w-full btn-delete"
              >
                Go to Home
              </button>
            </div>
          )}
      </div>
    </div>
  );
};

export default FirebaseCustomEmailActionHandler;
