import React from "react";
import Footer from "../components/Footer";
import AlgoliaSearch from "../components/AlgoliaSearch";
import HowItWorks from "../components/HowItWorks";
import FAQ from "../components/FAQ";
import LatestListings from "../components/LatestListings";
import BecomeLenderCTA from "../components/BecomeLenderCTA";
import GuaranteeHomepageBanner from "../components/GuaranteeHomepageBanner";
import { useAuthUser } from "../hooks/useAuthUser";
import SearchCategoryCards from "../components/SearchCategoryCards";

const Home = () => {
  const { authUser } = useAuthUser();

  return (
    <>
      {/* Hero Section with Animated Gradient on "anything" */}
      <div className="pt-24 pb-12 bg-gray-100">
        <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-center gap-8">
          <div className="w-full lg:w-2/3 xl:w-1/2 flex flex-col items-center md:items-start text-center md:text-left">
            <h1 className="text-3xl md:text-5xl font-bold text-gray-600">
              Hire <span className="animate-gradient">anything</span> from
              people in your area
            </h1>
            <p className="mt-6 md:mt-4 text-sm md:text-lg text-gray-600">
              Rent instead of buying—access tools, equipment, and creative gear
              with ease.
            </p>
            <div className="w-full max-w-3xl mt-6">
              <AlgoliaSearch />
            </div>
          </div>
          {/* <div className="w-full md:w-1/2 flex justify-center">
            <img
              src={lightbulbMoment}
              alt="Inspiration"
              className="w-48 md:w-64"
            />
          </div> */}
        </div>
        {/* Categories Section */}

        <SearchCategoryCards />
      </div>

      {/* Other components remain unchanged */}
      <GuaranteeHomepageBanner />

      <div className="flex justify-center py-2 bg-gray-100">
        {/* <div className="flex justify-center py-2 "> */}
        <HowItWorks />
      </div>

      <div>
        <LatestListings />
      </div>

      <BecomeLenderCTA authUser={authUser} />

      <FAQ />
      <Footer authUser={authUser} />
    </>
  );
};

export default Home;
