import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const FAQ = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const navigate = useNavigate();

  const faqs = [
    {
      question: "How much does it cost to list an item for rent?",
      for: "lenders",
      answer: <>It's free to list an item for rent on KitShare.</>,
    },
    {
      question: "How much does KitShare charge renters per rental?",
      for: "renters",
      answer: (
        <>
          Renters are charged a fixed service fee of 10% per rental. This fee
          helps us maintain the platform and provide customer support.
        </>
      ),
    },
    {
      question: "How do I get paid?",
      for: "lenders",
      answer: (
        <>
          We use Stripe and Stripe Connect to securely manage payments. Once you
          set up your Stripe Connect account, you'll have access to a dashboard
          with your transactions and payment history. When a rental is
          confirmed, the renter's payment is processed, and the funds are
          transferred to your Stripe balance the day after the rental is
          completed. Payouts to your linked bank account are initiated weekly.
        </>
      ),
    },
    {
      question: "When do I get paid from KitShare?",
      for: "lenders",
      answer: (
        <>
          Funds are transferred to your Stripe balance the day after the rental
          is completed. KitShare then initiates payouts from your Stripe balance
          to your linked bank account on a weekly basis.
        </>
      ),
    },
    {
      question: "How can I trust users on this platform?",
      for: "both",
      answer: (
        <>
          To ensure trust and security, all users are required to verify their
          ID through our trusted partner Veriff before they can rent or list
          items. Additionally, after each rental, we encourage users to leave
          reviews, which you can view on each user's profile.
        </>
      ),
    },
    {
      question: "Does KitShare provide a guarantee on rentals?",
      for: "lenders",
      answer: (
        <>
          Yes, KitShare provides a flat guarantee plan for all rentals. Lenders
          are charged a flat 15% service fee, which covers up to €2,500 per
          rental. For more information, please visit our dedicated{" "}
          <span
            onClick={() => navigate("/guarantee")}
            className="text-blue-500 underline hover:font-semibold cursor-pointer"
          >
            KitShare Guarantee
          </span>{" "}
          page.
        </>
      ),
    },
    {
      question: "How do I price my item for rent?",
      for: "lenders",
      answer: (
        <>
          We believe you should price your item's daily rate at roughly 3-5% of
          the total cost of the item. This is just a rough guideline and lenders
          are entitled to choose their own prices.
        </>
      ),
    },
    {
      question: "Are transactions secure with KitShare?",
      for: "both",
      answer: (
        <>
          Yes, all transactions on KitShare are secure. We use the
          industry-leading Stripe to process payments and transfers, ensuring
          that your sensitive payment information is handled safely. KitShare
          does not process or store any card information; all payment data is
          managed securely by Stripe.
        </>
      ),
    },
    {
      question: "How much does KitShare charge lenders per rental?",
      for: "lenders",
      answer: (
        <>
          KitShare charges lenders a flat 15% service fee for each rental. This
          fee is used to guarantee each rental up to €2,500 and contributes
          toward site management, hosting, and support. KitShare only takes this
          fee when you get paid; there are no upfront costs.
        </>
      ),
    },
    {
      question: "What happens if an item gets damaged during a rental?",
      for: "both",
      answer: (
        <>
          In the unfortunate event that your item is damaged during a rental,
          please contact us immediately at{" "}
          <a
            href="mailto:support@kitshare.ie"
            className="text-blue-500 underline"
          >
            support@kitshare.ie
          </a>{" "}
          or submit a claim request through our{" "}
          <span
            onClick={() => navigate("/claim")}
            className="text-blue-500 underline hover:font-semibold cursor-pointer"
          >
            claim portal
          </span>
          . We will assist you in resolving the issue.
        </>
      ),
    },
    {
      question: "How do I list an item for rent?",
      for: "lenders",
      answer: (
        <>
          Listing an item is simple. After creating your account and verifying
          your email, navigate to the "List an Item" page, fill in the required
          details, upload high-quality photos, and set your rental price and
          availability. Once submitted, your item will be visible to renters on
          the platform.
        </>
      ),
    },
    {
      question:
        "Can I communicate with renters or lenders before confirming a rental?",
      for: "both",
      answer: (
        <>
          Yes, KitShare provides an in-platform messaging system that allows
          renters and lenders to communicate safely. You can ask questions,
          discuss details, and coordinate arrangements before confirming a
          rental.
        </>
      ),
    },
    {
      question: "What is the lender cancellation policy?",
      for: "lenders",
      answer: (
        <>
          There are no cancellation fees for Lenders if they cancel their
          bookings. However, KitShare closely monitors cancellations and if
          Lenders are frequently cancelling bookings, restrictions may be
          imposed on their accounts.
        </>
      ),
    },
    {
      question: "What is the renter cancellation policy?",
      for: "renters",
      answer: (
        <div className="flex flex-col">
          <p>
            <span className="font-semibold text-gray-800">
              10% Cancellation Fee:
            </span>{" "}
            If a Renter cancels within 7 days of the rental start date.
          </p>
          <p>
            <span className="font-semibold text-gray-800">
              20% Cancellation Fee:
            </span>{" "}
            If a Renter cancels on or the day before the rental start date.
          </p>
          <p>
            Cancellation fees are collected by KitShare and distributed directly
            to the Lender.
          </p>
        </div>
      ),
    },
    {
      question: "How does the deposit system work?",
      for: "both",
      answer: (
        <>
          Lenders have the option to request a security deposit for each rental.
          This deposit must be collected manually by the lender before the
          rental begins and returned to the renter at the end of the rental if
          no issues arise.
        </>
      ),
    },
    {
      question: "Do I need to pay taxes on my earnings?",
      for: "lenders",
      answer: (
        <>
          Earnings from renting out your items may be subject to taxes,
          depending on your local laws. We recommend consulting with a tax
          professional to understand your obligations. KitShare provides
          transaction history via Stripe to assist with record-keeping.
        </>
      ),
    },
    {
      question: "What items can I list on KitShare?",
      for: "lenders",
      answer: (
        <>
          You can list a wide range of items for rental, including photography
          equipment, tools, sports gear, and more. Prohibited items include
          weapons, illegal goods, and any items that violate our terms. Please
          refer to our{" "}
          <span
            onClick={() => navigate("/terms-and-conditions")}
            className="text-blue-500 underline hover:font-semibold cursor-pointer"
          >
            Terms and Conditions
          </span>{" "}
          for more details.
        </>
      ),
    },
    {
      question: "How does the ID verification process work?",
      for: "both",
      answer: (
        <>
          We partner with Veriff to securely verify users' identities. During
          the verification process, you will be asked to provide a valid
          government-issued ID and a selfie. Veriff uses advanced technology to
          verify your identity and enhance platform safety. We do not store any
          verification data on our site.
        </>
      ),
    },
    {
      question: "What should I do if I encounter a problem with a rental?",
      for: "both",
      answer: (
        <>
          If you experience any issues during a rental, please contact our
          support team at{" "}
          <a
            href="mailto:support@kitshare.ie"
            className="text-blue-500 underline"
          >
            support@kitshare.ie
          </a>
          . We will work with you to resolve any problems and ensure a positive
          experience.
        </>
      ),
    },
  ];

  // Group FAQs by category based on the "for" property, defaulting to "both"
  const lendersFaqs = faqs.filter(
    (faq) => (faq.for ? faq.for.toLowerCase() : "both") === "lenders"
  );
  const rentersFaqs = faqs.filter(
    (faq) => (faq.for ? faq.for.toLowerCase() : "both") === "renters"
  );
  const bothFaqs = faqs.filter(
    (faq) => (faq.for ? faq.for.toLowerCase() : "both") === "both"
  );

  const toggleFAQ = (key) => {
    setSelectedQuestion(selectedQuestion === key ? null : key);
  };

  const renderFAQSection = (title, faqsArray, prefix) => (
    <>
      {faqsArray.length > 0 && (
        <section className="mb-8">
          <h2 className="text-lg md:text-xl font-semibold mb-4 text-teal-700">
            {title}
          </h2>
          {faqsArray.map((faq, i) => {
            // Construct a unique key for each FAQ using the section prefix and index
            const key = `${prefix}-${i}`;
            return (
              <div
                key={key}
                onClick={() => toggleFAQ(key)}
                className="faq-item mb-4 p-4 rounded-lg bg-white shadow-md hover:shadow-lg transition-shadow duration-200 cursor-pointer"
              >
                <div className="faq-question flex justify-between items-center md:text-lg font-semibold text-gray-700">
                  {faq.question}
                  <FontAwesomeIcon
                    icon={
                      selectedQuestion === key ? faChevronUp : faChevronDown
                    }
                    className="text-gray-500"
                  />
                </div>
                <div
                  className="faq-answer overflow-hidden transition-all duration-300"
                  style={{
                    maxHeight: selectedQuestion === key ? "1000px" : "0",
                  }}
                >
                  <div className="mt-2 text-gray-600 text-sm md:text-base leading-relaxed">
                    {faq.answer}
                  </div>
                </div>
              </div>
            );
          })}
        </section>
      )}
    </>
  );

  return (
    <div className="bg-gray-50 py-5 md:py-10">
      <div className="flex w-full justify-center">
        <h1 className="w-full text-center md:w-2/3 mb-8 text-xl md:text-2xl font-semibold text-gray-600">
          Frequently Asked Questions
        </h1>
      </div>
      <div className="flex justify-center">
        <div className="w-11/12 md:w-8/12 lg:w-6/12">
          {renderFAQSection("For Lenders", lendersFaqs, "lenders")}
          {renderFAQSection("For Renters", rentersFaqs, "renters")}
          {renderFAQSection("For Both Lenders & Renters", bothFaqs, "both")}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
