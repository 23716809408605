import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../firebase.config";
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  collection,
  query,
  getDocs,
  limit,
  orderBy,
} from "firebase/firestore";
// import ImageSlider from "../components/Listing/ImageSlider";
import ListingDetails from "../components/Listing/ListingDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCancel,
  faCheckCircle,
  faShare,
  faShareNodes,
  faStar,
  faXmarkSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  FaRegCalendarCheck,
  FaRegHeart,
  FaArrowRightFromBracket,
  FaHeartCircleXmark,
} from "react-icons/fa6";

import GoogleMapComponent from "../components/Listing/GoogleMapComponent";
import useListing from "../hooks/useListing";
import { useAuthUser } from "../hooks/useAuthUser";
import { useUserDoc } from "../hooks/useUserDoc";
import "react-datepicker/dist/react-datepicker.css";
import EnquireModal from "../components/Listing/EnquireModal";
import ConfirmDeleteListingModal from "../components/Listing/ConfirmDeleteListingModal";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import profilePicturePlaceholder from "../assets/profilePicture.png";
import { BeatLoader } from "react-spinners";
import ListingItemLenderReviews from "../components/Listing/ListingItemLenderReviews";
import ImageGallery from "react-image-gallery";
import "../css/ImageGallery.css";
import OtherItemsOwnedBy from "../components/Listing/OtherItemsOwnedBy";
import VerifyIDModal from "../components/Listing/VerifyIDModal";
import { checkEmailVerified } from "../utils/verificationUtils";

const Listing = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isEnquireModalOpen, setIsEnquireModalOpen] = useState(false);
  const [isConfirmDeleteListingModalOpen, setIsConfirmDeleteListingModalOpen] =
    useState(false);
  const [isFavourited, setIsFavourited] = useState(false);
  const [addingToFavourites, setAddingToFavourites] = useState(false);
  const [itemLenderRecentReviews, setItemLenderRecentReviews] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [itemLenderDetails, setItemLenderDetails] = useState({});
  const [error, setError] = useState(null);
  const [images, setImages] = useState([]);
  const [showVerifyIDModal, setShowVerifyIDModal] = useState(false);
  const { listingId } = useParams();
  const { listing, loading } = useListing(listingId);
  const { authUser } = useAuthUser();
  const { userDoc } = useUserDoc();
  const navigate = useNavigate();

  const checkEmailAndIDVerificationThenShowCalendarModal = async () => {
    if (!authUser) {
      toast.info("You need to be logged in to enquire about a listing.", {
        autoClose: 3000,
      });
      return;
    } else if (!authUser.emailVerified) {
      const emailVerified = await checkEmailVerified();
      if (emailVerified === true) {
        setIsEnquireModalOpen(true);
        return;
      }

      toast.info("You need to verify your email to enquire about listings.", {
        autoClose: 5000,
      });
      return;
    } else if (userDoc?.veriff?.decision !== "approved") {
      setShowVerifyIDModal(true);
    } else {
      setIsEnquireModalOpen(true);
    }
  };

  useEffect(() => {
    const getUserAndFavourites = async () => {
      if (authUser) {
        try {
          const userRef = doc(db, "users", authUser.uid);
          const userSnap = await getDoc(userRef);

          if (userSnap.exists()) {
            const isFavourite = userSnap.data().favourites?.includes(listingId);
            setIsFavourited(isFavourite);
          }
        } catch (error) {
          console.log(error.message);
          toast.error("Failed to fetch user data.");
        }
      }
    };

    getUserAndFavourites();
  }, [authUser, listingId]);

  const fetchReviews = async (lenderUid) => {
    try {
      // setIsLoading(true);
      const reviewsRef = collection(db, "users", lenderUid, "reviews");
      const q = query(reviewsRef, orderBy("createdAt", "desc"), limit(4));
      const querySnapshot = await getDocs(q);

      const reviewsArray = [];
      querySnapshot.forEach((doc) => {
        reviewsArray.push({ id: doc.id, ...doc.data() });
      });
      setItemLenderRecentReviews(reviewsArray);
      // setIsLoading(false);
    } catch (error) {
      console.log(error);
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    const getItemLenderDetails = async () => {
      try {
        const itemLenderRef = doc(db, "users", listing?.lenderUid);
        const itemLenderSnap = await getDoc(itemLenderRef);
        if (itemLenderSnap.exists()) {
          const itemLenderData = {
            ...itemLenderSnap.data(),
            uid: itemLenderSnap.id,
          };
          setItemLenderDetails(itemLenderData);
          fetchReviews(itemLenderData.uid);
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    if (listing?.lenderUid) {
      getItemLenderDetails();
    }
  }, [listing]);

  useEffect(() => {
    if (loading) {
      setIsLoading(true);
    } else {
      if (!listing) {
        setError("Listing does not exist");
        setIsLoading(false);
      } else {
        let imageArray = [];
        // Get listing image URLs and format correctly for react-image-gallery
        listing.imageURLs.forEach((image) => {
          imageArray.push({
            original: image,
            thumbnail: image,
          });
        });
        setImages(imageArray);
        setIsActive(listing.isActive);
        setIsLoading(false);
      }
    }
  }, [loading, listing]);

  const showConfirmDeleteListingModal = () => {
    setIsConfirmDeleteListingModalOpen(true);
  };

  const handleToggleFavourite = async () => {
    if (!authUser) {
      toast.error("You need to be logged in to manage favourites.", {
        autoClose: 2000,
      });
      return;
    }

    const userDocRef = doc(db, "users", authUser.uid);
    setAddingToFavourites(true); // for a loading spinner if you like

    try {
      if (isFavourited) {
        // Remove from favourites
        await updateDoc(userDocRef, {
          favourites: arrayRemove(listingId),
        });
        toast.success("Removed from favourites!", { autoClose: 3000 });
        setIsFavourited(false);
      } else {
        // Add to favourites
        await updateDoc(userDocRef, {
          favourites: arrayUnion(listingId),
        });
        toast.success("Added to favourites!", { autoClose: 3000 });
        setIsFavourited(true);
      }
    } catch (error) {
      console.error("Error updating favourites: ", error);
      toast.error("Failed to update favourites.");
    } finally {
      setAddingToFavourites(false);
    }
  };

  const changeListingStatus = async () => {
    setShowSpinner(true);

    try {
      setIsActive(!isActive);
      const listingRef = doc(db, "listings", listingId);
      // Toggle isActive
      await updateDoc(listingRef, {
        isActive: !isActive,
      });

      // Show a success message based on the new value
      toast.success(
        `You have updated the listing to be ${
          isActive ? "inactive" : "active"
        }.`
      );
    } catch (error) {
      toast.error("Failed to change status: " + error.message);
    } finally {
      setShowSpinner(false);
    }
  };

  const handleNavigateToDateSelection = () => {
    if (authUser) {
      navigate(`/listings/${listingId}/date-selection`);
    } else {
      toast.info("You need to be logged in to enquire about a listing.", {
        autoClose: 3000,
      });
    }
  };

  const shareListing = () => {
    const listingUrl = `www.kitshare.ie/listings/${listingId}`;
    navigator.clipboard.writeText(listingUrl);
    console.log(listingUrl);
    toast.success("Listing URL copied to clipboard!", { autoClose: 3000 });
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <div className="flex justify-center items-center mt-10">
        <div className="text-center">
          <h1 className="text-2xl font-semibold">Listing Not Found</h1>
          <p>{error}</p>
          <button
            className="text-sm md:text-base mt-4 px-4 py-2 bg-teal-500 text-white rounded-md hover:font-semibold hover:bg-teal-600"
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Go Back
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <EnquireModal
        isOpen={isEnquireModalOpen}
        onClose={() => setIsEnquireModalOpen(false)}
        listing={listing}
      />

      <ConfirmDeleteListingModal
        isOpen={isConfirmDeleteListingModalOpen}
        onClose={() => setIsConfirmDeleteListingModalOpen(false)}
        listing={listing}
        authUser={authUser}
      />

      <VerifyIDModal
        showVerifyIDModal={showVerifyIDModal}
        onClose={() => setShowVerifyIDModal(false)}
        authUser={authUser}
        userDoc={userDoc}
      />

      <div className="flex justify-center">
        <div className="w-full p-2 sm:w-4/5 xl:w-2/3 2xl:w-3/5">
          <div className="my-3">
            <p
              className="font-semibold hover:font-bold hover:cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Go back
            </p>
          </div>
          {/* Container for Listing Details and Image Slider */}
          <div className="flex flex-col-reverse w-full lg:flex-row justify-center">
            <div className="w-full lg:w-2/3">
              <ListingDetails
                listingDetails={listing}
                listingId={listingId}
                isLoading={isLoading}
              />

              <GoogleMapComponent mapCenter={listing._geoloc} />

              {/* Item Lender Details */}
              <div className="flex flex-col mt-5 bg-gray-100 p-2 rounded-md shadow-md">
                <p className="font-semibold text-gray-700 mb-2">Lender</p>
                <div className="flex justify-between items-center align-center">
                  <div
                    onClick={() => navigate(`/users/${itemLenderDetails.uid}`)}
                    className="flex jusity-center items-center align-center hover:cursor-pointer hover:font-semibold"
                  >
                    <img
                      src={
                        itemLenderDetails?.profilePictureURL
                          ? itemLenderDetails.profilePictureURL
                          : profilePicturePlaceholder
                      }
                      className="w-8 h-8 rounded-full"
                      alt="profile"
                    ></img>
                    <p className="ml-2">{itemLenderDetails.username}</p>
                  </div>
                  {itemLenderDetails.reviewCount > 0 ? (
                    <div className="flex justify-center align-center items-center gap-2">
                      <p className="">
                        {itemLenderDetails.averageReviewRating}{" "}
                        <FontAwesomeIcon icon={faStar} color="gold" />
                      </p>
                      <p className="text-xs mt-1 font-semibold">
                        ({itemLenderDetails.reviewCount})
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>

              {/* Item lender reviews  */}
              {itemLenderRecentReviews.length > 0 ? (
                <ListingItemLenderReviews reviews={itemLenderRecentReviews} />
              ) : null}
            </div>
            <div className="md:ml-5 w-full lg:w-3/5">
              {/* <ImageSlider isLoading={loading} images={listing.imageURLs} /> */}
              <div className="flex justify-center">
                <div className="w-4/5 sm:mb-5">
                  {isLoading ? (
                    <BeatLoader />
                  ) : (
                    <ImageGallery
                      items={images}
                      autoPlay={true}
                      showPlayButton={false}
                      slideDuration={600}
                      showNav={false}
                      showFullscreenButton={false}
                      showBullets={false}
                      showThumbnails={false}
                    />
                  )}
                </div>
              </div>

              {/* Component to either enquire or edit listing */}
              {authUser && authUser.uid === listing.lenderUid ? (
                <div className="flex flex-col items-center align-center gap-5 justify-center my-5">
                  <button
                    className="w-1/2 btn-edit px-4 py-2"
                    onClick={() => navigate(`/edit-listing/${listingId}`)}
                  >
                    <div className="flex justify-center align-center items-center gap-2 text-sm md:text-base">
                      <FaArrowRightFromBracket />
                      <span>Edit Listing</span>
                    </div>
                  </button>
                  <button
                    className="w-1/2 btn-delete px-4 py-2 text-sm md:text-base"
                    onClick={() => showConfirmDeleteListingModal()}
                  >
                    <FontAwesomeIcon icon={faXmarkSquare} /> Delete Listing
                  </button>

                  <button
                    type="button"
                    onClick={changeListingStatus}
                    className={`${
                      isActive ? "btn-inactive" : "btn-continue"
                    } w-1/2 px-4 py-2 text-sm md:text-base`}
                    disabled={showSpinner}
                  >
                    {isActive ? (
                      <p>
                        <FontAwesomeIcon icon={faCancel} /> Make Inactive
                      </p>
                    ) : (
                      <p>
                        <FontAwesomeIcon icon={faCheckCircle} /> Make Active
                      </p>
                    )}
                  </button>

                  <button
                    className="w-1/2 btn-primary px-4 py-2 text-sm md:text-base"
                    onClick={() => shareListing()}
                  >
                    <FontAwesomeIcon icon={faShareNodes} /> Share Listing
                  </button>
                </div>
              ) : (
                // Enquire or add to favourites
                <div>
                  <div className="flex justify-center my-5">
                    <button
                      className="w-1/2 btn-continue px-4 py-2"
                      onClick={() => handleNavigateToDateSelection()}
                    >
                      <div className="flex justify-center align-center items-center gap-2 text-sm md:text-base">
                        <FaRegCalendarCheck />
                        <span>Check Availability</span>
                      </div>
                    </button>
                    {/* <button
                      className="w-1/2 btn-continue px-4 py-2"
                      onClick={checkEmailAndIDVerificationThenShowCalendarModal}
                    >
                      <div className="flex justify-center align-center items-center gap-2 text-sm md:text-base">
                        <FaRegCalendarCheck />
                        <span>Check Availability</span>
                      </div>
                    </button> */}
                  </div>
                  <div className="flex justify-center my-5 text-sm md:text-base">
                    {isFavourited ? (
                      <button
                        className="w-1/2 rounded-md btn-delete px-4 py-2"
                        onClick={() => handleToggleFavourite()}
                      >
                        <div className="flex justify-center align-center items-center gap-2">
                          <FaHeartCircleXmark />
                          <span>Remove Favourite</span>
                        </div>
                      </button>
                    ) : (
                      <button
                        className="w-1/2 btn-delete px-4 py-2"
                        onClick={() => handleToggleFavourite()}
                      >
                        {addingToFavourites ? (
                          <BeatLoader color="white" />
                        ) : (
                          <div className="flex justify-center align-center items-center gap-2">
                            <FaRegHeart />
                            <span>Add to Favourites</span>
                          </div>
                        )}{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              <div></div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full justify-center">
        <div className="w-full">
          {/* Other items owned by user - will show null if no other items are owned by this user  */}
          <OtherItemsOwnedBy
            itemLenderDetails={itemLenderDetails}
            currentListingId={listingId}
          />
        </div>
      </div>
    </div>
  );
};

export default Listing;
