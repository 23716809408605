import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase.config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import algoliasearch from "algoliasearch";
import profilePicturePlaceholder from "../../../assets/profilePicture.png";
import formatCurrency from "../../../utils/formatCurrency";
import ListingDetails from "./ListingDetails";

// Initialize Algolia client - match the pattern from SearchResults.jsx
const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

const Listings = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [selectedListing, setSelectedListing] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const navigate = useNavigate();

  const handleSearch = async (e) => {
    // If event is provided, it's an initial search
    if (e) {
      e.preventDefault();
      // Reset pagination when starting a new search
      setPage(0);
      setTotalPages(0);
      setResults([]);
      setSelectedListing(null);
    }

    if (!searchTerm.trim()) return;

    setLoading(true);
    setError(null);

    try {
      let indexName = "kitshare_listings";
      const index = searchClient.initIndex(indexName);

      const searchParams = {
        hitsPerPage: 10,
        page: page,
      };

      // Perform the Algolia search
      const { hits, nbPages } = await index.search(searchTerm, searchParams);

      // If this is the first page and no hits, try direct ID lookup
      if (hits.length === 0 && page === 0) {
        try {
          const listingDocRef = doc(db, "listings", searchTerm);
          const listingDocSnap = await getDoc(listingDocRef);

          if (listingDocSnap.exists()) {
            let data = listingDocSnap.data();
            data.id = listingDocSnap.id;
            setResults([data]);
          }
        } catch (idLookupError) {
          console.error("ID lookup error:", idLookupError);
          setResults([]);
        }
      } else {
        // Format hits to include ID
        const formattedResults = hits.map((hit) => ({
          ...hit,
          id: hit.objectID,
        }));

        // Append or set results based on whether we're loading more
        if (page === 0) {
          setResults(formattedResults);
        } else {
          // Add only unique listings based on ID
          setResults((prevResults) => {
            // Get current IDs to prevent duplicates
            const existingIds = new Set(prevResults.map((item) => item.id));
            // Filter out any new results that already exist in our results
            const uniqueNewResults = formattedResults.filter(
              (item) => !existingIds.has(item.id)
            );

            return [...prevResults, ...uniqueNewResults];
          });
        }

        setTotalPages(nbPages);
      }
    } catch (err) {
      console.error("Search error:", err);
      setError("Error searching listings. Please try again.");
    }

    setLoading(false);
  };

  const handleShowMore = () => {
    // Increment page and trigger search for next page
    setPage((prevPage) => prevPage + 1);
  };

  // Watch for page changes and load more results
  useEffect(() => {
    // Only load more if page > 0 (initial load handled by search button)
    if (page > 0) {
      handleSearch();
    }
  }, [page]);

  const handleSelectListing = (listing) => {
    setSelectedListing(listing);
  };

  const handleActionComplete = (action, listingId) => {
    if (action === "deleted") {
      // Remove the listing from results
      setResults((prevResults) =>
        prevResults.filter((listing) => listing.id !== listingId)
      );
      setSelectedListing(null);
    } else if (action === "inactive") {
      // Update the listing in results
      setResults((prevResults) =>
        prevResults.map((listing) =>
          listing.id === listingId ? { ...listing, isActive: false } : listing
        )
      );
      // Also update the selected listing if it's the same one
      if (selectedListing && selectedListing.id === listingId) {
        setSelectedListing((prev) => ({ ...prev, isActive: false }));
      }
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-xl font-semibold mb-2 text-gray-700">
        Listings Management
      </h2>
      <form onSubmit={handleSearch} className="flex items-center mb-6">
        <div className="relative w-full">
          <input
            type="text"
            placeholder="Search listings by title, description or ID..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full border border-gray-300 rounded-md py-2 pl-10 pr-4 outline-none focus:border-2 focus:border-teal-600"
          />
        </div>
        <button type="submit" className="btn-primary mx-5 px-5 py-2">
          {loading && page === 0 ? "Searching..." : "Search"}
        </button>
      </form>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {results.length > 0 && (
        <div className="mb-6">
          <h3 className="text-xl font-medium text-gray-700 mb-2">
            Search Results
          </h3>
          <ul className="divide-y divide-gray-200">
            {results.map((listing) => (
              <li
                key={listing.id}
                className="py-3 flex items-center cursor-pointer hover:bg-gray-100"
                onClick={() => handleSelectListing(listing)}
              >
                <img
                  src={listing.imageURLs?.[0] || profilePicturePlaceholder}
                  alt={listing.title}
                  className="w-20 h-20 mr-4 object-cover rounded"
                />
                <div className="flex-1">
                  <p className="font-medium">{listing.title}</p>
                  <p className="text-sm text-gray-600">
                    €{formatCurrency(listing.dailyRateCents)} per day
                  </p>
                </div>
                <div className="text-sm">
                  {listing.isActive ? (
                    <span className="text-green-500 flex items-center">
                      <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />{" "}
                      Active
                    </span>
                  ) : (
                    <span className="text-red-500 flex items-center">
                      <FontAwesomeIcon icon={faTimesCircle} className="mr-1" />{" "}
                      Not active
                    </span>
                  )}
                </div>
              </li>
            ))}
          </ul>

          {/* Show More Button */}
          {page < totalPages - 1 && (
            <div className="flex justify-center mt-4">
              <button
                onClick={handleShowMore}
                className="px-4 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-700 transition-colors"
                disabled={loading}
              >
                {loading ? "Loading..." : "Show More"}
              </button>
            </div>
          )}
        </div>
      )}
      {selectedListing && (
        <ListingDetails
          listing={selectedListing}
          navigate={navigate}
          onActionComplete={handleActionComplete}
        />
      )}
    </div>
  );
};

export default Listings;
