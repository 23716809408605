import React, { useState } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import { BeatLoader } from "react-spinners";

const DynamicChatModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  isLoading,
  confirmText,
  cancelText,
  showInputField,
}) => {
  const [inputValue, setInputValue] = useState("");

  const customStyles = {
    content: {
      inset: "50% auto auto 50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      padding: "15px",
      maxWidth: "600px",
      width: "90%",
      border: "none",
      boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };

  const handleClose = (e) => {
    setInputValue("");
    onClose();
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleConfirm = () => {
    onConfirm(inputValue);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Dynamic Modal"
      ariaHideApp={false}
    >
      <div className="">
        {/* <div
          className="absolute top-2 right-4 hover:cursor-pointer hover:font-bold"
          onClick={handleClose}
        >
        </div> */}
        <div className="flex justify-between align-center items-center">
          <h2 className="font-semibold md:text-xl text-gray-700">{title}</h2>
          <div className="hover:cursor-pointer" onClick={handleClose}>
            <FontAwesomeIcon icon={faX} />
          </div>
        </div>
        <p className="text-sm md:text-base my-4 text-gray-700">{message}</p>
        <p className="text-xs md:text-sm mb-4 text-gray-700">
          Selecting "Yes" will delete this chat and rental request.
        </p>
        {showInputField && (
          <div className="flex flex-col my-5">
            <label className="text-left text-sm font-semibold text-gray-700">
              Provide reason to user (optional)
            </label>
            <input
              className="text-sm outline-none focus:border-2 focus:border-teal-600"
              placeholder="optional..."
              value={inputValue}
              onChange={handleInputChange}
            />
          </div>
        )}
        <div className="flex justify-end gap-4">
          <button
            className="text-sm md:text-base btn-cancel px-4 py-2"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={faCancel} /> {" " + cancelText}
          </button>
          <button
            className="text-sm md:text-base btn-confirm px-4 py-2"
            onClick={handleConfirm}
            disabled={isLoading}
          >
            {isLoading ? (
              <BeatLoader color="white" />
            ) : (
              <FontAwesomeIcon icon={faCheck} />
            )}
            {" " + confirmText}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DynamicChatModal;
