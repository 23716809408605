import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedal } from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/Footer";

const Guarantee = () => {
  return (
    <>
      <div className="bg-gray-50 pt-5 px-5">
        <div className="max-w-6xl mx-auto">
          {/* Friendly Welcome Message */}
          <div className="text-center mb-10">
            <h1 className="text-xl md:text-2xl text-gray-700 font-semibold mb-8">
              KitShare Guarantee
            </h1>
          </div>

          {/* Guarantee Plan Card */}
          <div className="flex justify-center mb-10">
            <div className="bg-white rounded-lg shadow-lg p-8 md:p-10 w-full md:w-2/3">
              <div className="flex flex-col items-center mb-4">
                <FontAwesomeIcon
                  icon={faMedal}
                  size="4x"
                  style={{ color: "#109588" }}
                  className="mb-4"
                />
                <h2 className="text-xl md:text-2xl font-semibold text-gray-700 mb-2">
                  Our Guarantee Plan
                </h2>
                <p className="text-sm md:text-base text-gray-700 text-center mb-2">
                  At KitShare, we’re committed to protecting your valuable
                  items.
                </p>
                <p className="text-sm md:text-base text-gray-700 text-center">
                  Our simple guarantee ensures that your items are covered up to{" "}
                  <strong>€2,500</strong> for each rental, for a flat{" "}
                  <strong>15%</strong> service fee. Rent with confidence knowing
                  you’re safeguarded against theft and damage.
                </p>
              </div>
            </div>
          </div>

          {/* Policy Overview */}
          <div className="mt-12">
            <h2 className="text-lg md:text-2xl font-semibold mb-2 md:mb-4 text-gray-700">
              Policy Overview
            </h2>
            <p className="text-sm md:text-base text-gray-700 mb-4">
              KitShare provides theft and damage protection for items rented
              through our platform, subject to specific terms, exclusions, and
              responsibilities.
            </p>

            <h3 className="text-base md:text-xl font-semibold mb-2 text-gray-700">
              Coverage
            </h3>
            <ul className="list-disc list-inside text-sm md:text-base text-gray-700 mb-4 space-y-2">
              <li>
                <strong>Theft Protection:</strong> Compensation if your rented
                item is stolen during the rental period (police report
                required).
              </li>
              <li>
                <strong>Damage Protection:</strong> Coverage for repair or
                replacement costs if your item is damaged during the rental.
              </li>
            </ul>

            <h3 className="text-base md:text-xl font-semibold mb-2 text-gray-700">
              Exclusions
            </h3>
            <ul className="list-disc list-inside text-sm md:text-base text-gray-700 mb-4 space-y-2">
              <li>
                <strong>Intentional Damage:</strong> Damage caused intentionally
                or through gross negligence.
              </li>
              <li>
                <strong>Wear and Tear:</strong> Normal wear and tear or gradual
                deterioration.
              </li>
              <li>
                <strong>Improper Use:</strong> Damage from misuse or using the
                item contrary to its intended purpose.
              </li>
              <li>
                <strong>Pre-existing Conditions:</strong> Issues that existed
                before the rental period.
              </li>
              <li>
                <strong>Lender Negligence:</strong> Items not properly
                maintained or inadequately described.
              </li>
              <li>
                <strong>Renter's Negligence:</strong> Loss or theft due to the
                renter’s failure to take reasonable precautions.
              </li>
              <li>
                <strong>Non-return of Items:</strong> Failure to return the item
                due to negligence (e.g., forgetfulness).
              </li>
            </ul>

            <h3 className="text-base md:text-xl font-semibold mb-2 text-gray-700">
              Renter Responsibilities
            </h3>
            <ul className="list-disc list-inside text-sm md:text-base text-gray-700 mb-4 space-y-2">
              <li>
                <strong>Care of Items:</strong> Renters must take reasonable
                care to prevent damage or loss.
              </li>
              <li>
                <strong>Proper Use:</strong> Items should be used according to
                their intended purpose.
              </li>
              <li>
                <strong>Communication:</strong> Inform the lender promptly about
                any issues or damage during the rental period.
              </li>
              <li>
                <strong>Documentation:</strong> Provide time-stamped photos or
                videos before and after the rental to document the item’s
                condition.
              </li>
            </ul>

            <h3 className="text-base md:text-xl font-semibold mb-2 text-gray-700">
              Renter Liability
            </h3>
            <ul className="list-disc list-inside text-sm md:text-base text-gray-700 mb-4 space-y-2">
              <li>
                If a renter intentionally fails to return an item, uses it
                improperly, or is negligent, they are primarily liable.
              </li>
              <li>
                The lender should first attempt to contact the renter to resolve
                the issue directly.
              </li>
              <li>
                If the renter does not respond or resolve the issue, the lender
                should then contact KitShare for further assistance.
              </li>
            </ul>

            <h3 className="text-base md:text-xl font-semibold mb-2 text-gray-700">
              Claim Limits
            </h3>
            <p className="text-sm md:text-base text-gray-700 mb-4">
              Compensation under our guarantee plan is limited to a maximum of{" "}
              <strong>€2,500</strong> per rental transaction.
            </p>

            <h2 className="text-lg md:text-2xl font-semibold mb-2 md:mb-4 text-gray-700">
              Claim Process
            </h2>
            <p className="text-sm md:text-base mb-4 text-gray-700">
              If you experience damage or loss, follow these steps to file a
              claim:
            </p>

            <p className="text-sm md:text-base text-gray-700 mt-2">
              <strong>Report the Incident:</strong> Lenders must report theft or
              damage within 24 hours of the rental end date via the KitShare
              claims portal.
            </p>

            <p className="text-sm md:text-base text-gray-700 mt-2">
              <strong>Submit Required Documentation:</strong>
            </p>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-2 text-sm md:text-base text-gray-700">
              <li>
                <strong>Condition Evidence:</strong> Time-stamped before/after
                photos or videos of the item.
              </li>
              <li>
                <strong>Police Report:</strong> A report filed in cases of
                theft.
              </li>
              <li>
                <strong>Repair Estimate:</strong> For damage claims, obtain an
                estimate from a professional repair service.
              </li>
            </ul>

            <p className="text-sm md:text-base text-gray-700 mt-2">
              <strong>Investigation:</strong> KitShare will review all submitted
              documentation and may interview both parties involved.
            </p>

            <p className="text-sm md:text-base text-gray-700 mt-2">
              <strong>Decision:</strong> A decision will be made within 14
              business days. Both parties will be notified via email.
            </p>

            <p className="text-sm md:text-base text-gray-700 mt-2 mb-4">
              <strong>Payout:</strong> If your claim is approved, compensation
              up to the coverage limit will be provided within 7 business days.
            </p>

            <div className="mt-6 text-center">
              <Link
                to="/claim"
                className="btn-primary px-5 py-2 text-sm md:text-base font-semibold"
              >
                Submit a Claim
              </Link>
            </div>
          </div>

          {/* Dispute Resolution */}
          <div className="mt-8 pb-8">
            <h3 className="text-base md:text-lg font-semibold mb-2 text-gray-700">
              Dispute Resolution
            </h3>
            <p className="text-sm md:text-base text-gray-700 mb-4">
              If you disagree with a claim decision, you can request a review by
              KitShare's claims review panel. Their decision is final.
            </p>
            <p className="text-sm md:text-base mb-4">
              Email{" "}
              <a
                href="mailto:support@kitshare.ie"
                className="text-blue-500 underline"
              >
                support@kitshare.ie
              </a>{" "}
              for assistance with claims.
            </p>
            <p className="text-sm md:text-base text-gray-700">
              For full details, please refer to our{" "}
              <Link
                to="/terms-and-conditions"
                className="text-teal-600 font-semibold hover:underline hover:text-teal-800"
              >
                Terms &amp; Conditions.
              </Link>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Guarantee;
