import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../firebase.config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import profilePicturePlaceholder from "../../../assets/profilePicture.png";
import UserDetails from "./UserDetails";

const Users = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  // Simulated function to retrieve email verification status.
  // In production, use a secure Cloud Function (with the Admin SDK) or sync the value into Firestore.
  const getUserAuthData = async (uid) => {
    // Replace with your actual implementation.
    return { emailVerified: true };
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setResults([]);
    setSelectedUser(null);

    try {
      let foundUsers = [];

      // 1. Try to fetch by UID (document id)
      const userDocRef = doc(db, "users", searchTerm);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        let data = userDocSnap.data();
        data.uid = userDocSnap.id;
        const authData = await getUserAuthData(data.uid);
        data.emailVerified = authData.emailVerified;
        foundUsers.push(data);
      }

      // 2. If not found by UID, query by email, first name, and last name
      if (foundUsers.length === 0) {
        const usersRef = collection(db, "users");
        const queries = [
          query(usersRef, where("email", "==", searchTerm)),
          query(usersRef, where("firstName", "==", searchTerm)),
          query(usersRef, where("lastName", "==", searchTerm)),
        ];
        const querySnapshots = await Promise.all(
          queries.map((q) => getDocs(q))
        );
        querySnapshots.forEach((snapshot) => {
          snapshot.forEach((docSnap) => {
            let data = docSnap.data();
            data.uid = docSnap.id;
            if (!foundUsers.some((user) => user.uid === data.uid)) {
              foundUsers.push(data);
            }
          });
        });
        // Get email verification for each found user
        for (let i = 0; i < foundUsers.length; i++) {
          const authData = await getUserAuthData(foundUsers[i].uid);
          foundUsers[i].emailVerified = authData.emailVerified;
        }
      }

      setResults(foundUsers);
    } catch (err) {
      console.error("Search error:", err);
      setError("Error fetching user data.");
    }
    setLoading(false);
  };

  const handleSelectUser = (user) => {
    setSelectedUser(user);
  };

  return (
    <div className="p-6">
      <h2 className="text-xl font-semibold mb-2 text-gray-700">
        User Management
      </h2>
      <form onSubmit={handleSearch} className="flex items-center mb-6">
        <div className="relative w-full">
          <input
            type="text"
            placeholder="Search by UID, email, first name, last name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full border border-gray-300 rounded-md py-2 pl-10 pr-4 outline-none focus:border-2 focus:border-teal-600"
          />
        </div>
        <button type="submit" className="btn-primary py-2 mx-5 px-5">
          {loading ? "Searching..." : "Search"}
        </button>
      </form>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {results.length > 0 && (
        <div className="mb-6">
          <h3 className="text-xl font-medium text-gray-700 mb-2">
            Search Results
          </h3>
          <ul className="divide-y divide-gray-200">
            {results.map((user) => (
              <li
                key={user.uid}
                className="py-3 flex items-center cursor-pointer hover:bg-gray-100"
                onClick={() => handleSelectUser(user)}
              >
                <img
                  src={user.profilePictureURL || profilePicturePlaceholder}
                  alt={user.firstName}
                  className="w-10 h-10 rounded-full mr-4"
                />
                <div className="flex-1">
                  <p className="font-medium">
                    {user.firstName} {user.lastName}
                  </p>
                  <p className="text-sm text-gray-600">{user.email}</p>
                </div>
                <div className="text-sm">
                  {user.veriff?.decision === "approved" ? (
                    <span className="text-green-500 flex items-center">
                      <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />{" "}
                      ID Verified
                    </span>
                  ) : (
                    <span className="text-red-500 flex items-center">
                      <FontAwesomeIcon icon={faTimesCircle} className="mr-1" />{" "}
                      ID Unverified
                    </span>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      {selectedUser && <UserDetails user={selectedUser} navigate={navigate} />}
    </div>
  );
};

export default Users;
