import React, { useEffect, useState } from "react";
import { useAuthUser } from "../hooks/useAuthUser";
import { useUserDoc } from "../hooks/useUserDoc";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  increment,
  query,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase.config";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select, { components } from "react-select";
import firestoreTimestampToDate from "../utils/firestoreTimestampToDate";
import { BeatLoader } from "react-spinners";

const MAX_CLAIM_EUROS = 2500;

// Custom Option component for React Select
const Option = (props) => {
  const { data, isDisabled } = props;
  const booking = data.booking;

  return (
    <components.Option {...props} isDisabled={isDisabled}>
      <div className={`flex items-center ${isDisabled ? "opacity-50" : ""}`}>
        {booking.listingImage ? (
          <img
            src={booking.listingImage}
            alt={booking.listingTitle}
            className="w-12 h-12 object-cover rounded-md mr-3"
          />
        ) : (
          <div className="w-12 h-12 bg-gray-200 rounded-md mr-3 flex items-center justify-center">
            <span className="text-gray-500 text-xs">No Image</span>
          </div>
        )}
        <div>
          <p className="text-sm font-medium text-black">
            {booking.isOutsideClaimWindow
              ? `CLAIM EXPIRED - ${booking.listingTitle}`
              : booking.listingTitle}
          </p>
          <p className="text-xs text-gray-500">
            Rented by {booking.renterUsername} ({booking.startDateFormatted} -{" "}
            {booking.endDateFormatted})
          </p>
        </div>
      </div>
    </components.Option>
  );
};

const Claim = () => {
  const [bookings, setBookings] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [issueDescription, setIssueDescription] = useState("");
  const [requestedAmount, setRequestedAmount] = useState("");
  const [loading, setLoading] = useState(true);
  const [submittingClaim, setSubmittingClaim] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  // Cloudinary upload states
  const [imageFiles, setImageFiles] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);

  const navigate = useNavigate();
  const { authUser } = useAuthUser();
  const { userDoc } = useUserDoc();

  useEffect(() => {
    if (!authUser) {
      toast.error("You need to be logged in to submit a claim.", {
        autoClose: 4000,
      });
      navigate("/");
      return;
    }

    const fetchBookings = async () => {
      try {
        const userUid = authUser.uid;
        const bookingsRef = collection(db, "users", userUid, "bookings");
        const q = query(bookingsRef, orderBy("createdAt", "desc"));
        const bookingsSnapshot = await getDocs(q);

        const now = new Date();
        const bookingsData = [];

        for (const bookingDoc of bookingsSnapshot.docs) {
          const bookingData = bookingDoc.data();
          const listingId = bookingData.listingId;

          // Fetch listing details
          const listingDocRef = doc(db, "listings", listingId);
          const listingDocSnap = await getDoc(listingDocRef);
          const listingData = listingDocSnap.exists()
            ? listingDocSnap.data()
            : {};

          const listingTitle = listingData.title || "Unknown Listing";
          const listingImage = listingData.imageURLs
            ? listingData.imageURLs[0]
            : null;

          // Fetch renter username
          const renterUid = bookingData.renterUid;
          const renterDocRef = doc(db, "users", renterUid);
          const renterDocSnap = await getDoc(renterDocRef);
          const renterUsername = renterDocSnap.exists()
            ? renterDocSnap.data().username
            : "Unknown User";

          // Convert Firestore timestamps to JS Date objects
          const rawStartDate = bookingData.startDate
            ? firestoreTimestampToDate(bookingData.startDate)
            : null;
          const rawEndDate = bookingData.endDate
            ? firestoreTimestampToDate(bookingData.endDate)
            : null;

          // Format dates for display
          const startDateFormatted = rawStartDate
            ? rawStartDate.toLocaleDateString()
            : "Unknown Date";
          const endDateFormatted = rawEndDate
            ? rawEndDate.toLocaleDateString()
            : "Unknown Date";

          // Determine claim eligibility:
          // Claim allowed if current date is between endDate and endDate + 2 days.
          let isOutsideClaimWindow = true;
          if (rawEndDate) {
            const differenceInTime = now - rawEndDate;
            const differenceInDays = differenceInTime / (1000 * 3600 * 24);
            if (differenceInDays >= 0 && differenceInDays <= 2) {
              isOutsideClaimWindow = false;
            }
          }

          bookingsData.push({
            id: bookingDoc.id,
            listingTitle,
            listingImage,
            renterUsername,
            rawStartDate,
            rawEndDate,
            startDateFormatted,
            endDateFormatted,
            isOutsideClaimWindow,
            ...bookingData,
          });
        }

        setBookings(bookingsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching bookings:", error);
        toast.error("Failed to load bookings.");
        setLoading(false);
      }
    };

    fetchBookings();
  }, [authUser, navigate]);

  useEffect(() => {
    loadCloudinaryScript(() => {});
  }, []);

  const loadCloudinaryScript = (callback) => {
    const existingScript = document.getElementById("cloudinaryWidgetScript");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://widget.cloudinary.com/v2.0/global/all.js";
      script.id = "cloudinaryWidgetScript";
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    } else if (callback) {
      callback();
    }
  };

  // Cloudinary Upload Widget
  const cloudName = "prodCloudinary";
  const uploadPreset = "kitShareUploadPreset";

  const openUploadWidget = () => {
    window.cloudinary
      .createUploadWidget(
        {
          cloudName,
          uploadPreset,
          multiple: true,
          maxFiles: 5 - imageFiles.length,
          sources: ["local", "camera"],
          folder: "claims",
          context: {
            alt: "user_uploaded_image",
            caption: "Uploaded on KitShare",
          },
          resourceType: "auto",
        },
        (error, result) => {
          if (!error && result && result.event === "success") {
            setImageFiles((prevFiles) => [...prevFiles, result.info]);
            setImageURLs((prevUrls) => [...prevUrls, result.info.secure_url]);
          } else if (error) {
            console.error("Cloudinary upload error:", error);
            toast.error("Failed to upload file. Please try again.");
          }
        }
      )
      .open();
  };

  const removeImage = (index) => {
    setImageFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setImageURLs((prevUrls) => prevUrls.filter((_, i) => i !== index));
  };

  const handleBookingChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSelectedBooking(selectedOption.booking);
  };

  const incrementClaimCount = async () => {
    const userRef = doc(db, "users", authUser.uid);
    await updateDoc(userRef, {
      claimCount: increment(1),
    });
  };

  // Send claim email and Slack notification to support
  const sendClaimEmailAndSlackMessage = async (booking, claimDocId) => {
    const body = JSON.stringify({
      username: userDoc.username,
      uid: authUser.uid,
      bookingId: booking.id,
      listingId: booking.listingId,
      listingTitle: booking.listingTitle,
      startDate: rawDateToFormatted(booking.startDate),
      endDate: rawDateToFormatted(booking.endDate),
      renterUsername: booking.renterUsername,
      renterUid: booking.renterUid,
      issueDescription,
      requestedAmountCents: parseFloat(requestedAmount * 100),
      imageURLs,
      claimDocId,
    });

    try {
      const response = await fetch(
        `https://claimemail-claimemail-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body,
        }
      );
      const data = await response.json();
      console.log("Claim email sent:", data);
    } catch (error) {
      toast.error(
        "Failed to send email to KitShare, please contact us using other methods.",
        { autoClose: 3000 }
      );
    }
  };

  // Helper to format Firestore timestamp to locale string
  const rawDateToFormatted = (timestamp) => {
    const d = firestoreTimestampToDate(timestamp);
    return d ? d.toLocaleDateString() : "Unknown Date";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmittingClaim(true);

    if (!selectedBooking) {
      toast.error("Please select a booking.");
      setSubmittingClaim(false);
      return;
    }
    if (!issueDescription.trim()) {
      toast.error("Please describe the issue.");
      setSubmittingClaim(false);
      return;
    }
    if (!requestedAmount || isNaN(requestedAmount) || requestedAmount <= 0) {
      toast.error("Please enter a valid requested amount.");
      setSubmittingClaim(false);
      return;
    }
    if (parseFloat(requestedAmount) > MAX_CLAIM_EUROS) {
      toast.error(`Requested amount cannot exceed €${MAX_CLAIM_EUROS}.`);
      setSubmittingClaim(false);
      return;
    }
    if (imageFiles.length === 0) {
      toast.error("Please upload the required documents.");
      setSubmittingClaim(false);
      return;
    }

    try {
      const claimsRef = collection(db, "claims");
      const claimsSnapshot = await getDocs(claimsRef);
      const existingClaim = claimsSnapshot.docs.find(
        (doc) =>
          doc.data().userUid === authUser.uid &&
          doc.data().bookingId === selectedBooking.id
      );
      if (existingClaim) {
        toast.error(
          "You've already submitted a claim for this booking. Please wait for a response."
        );
        setSubmittingClaim(false);
        return;
      }

      const claimData = {
        userUid: authUser.uid,
        bookingId: selectedBooking.id,
        issueDescription,
        requestedAmountCents: parseFloat(requestedAmount * 100),
        uploadedFiles: imageURLs,
        createdAt: new Date(),
        status: "Pending",
      };

      const claimDoc = await addDoc(claimsRef, claimData);
      await sendClaimEmailAndSlackMessage(selectedBooking, claimDoc.id);
      await incrementClaimCount();

      toast.success(
        "Your claim has been submitted. KitShare aims to respond within 7 days."
      );
      setSubmittingClaim(false);
      navigate("/my-bookings");
    } catch (error) {
      console.error("Error submitting claim:", error);
      toast.error("Failed to submit your claim. Please try again.");
      setSubmittingClaim(false);
    }
  };

  // Prepare options for React Select
  const bookingOptions = bookings.map((booking) => {
    const labelBase = booking.listingTitle;
    let labelSuffix = "";
    if (booking.isOutsideClaimWindow) {
      labelSuffix = " (Claim expired)";
    }
    return {
      value: booking.id,
      label: labelSuffix ? `${labelBase} ${labelSuffix}` : labelBase,
      booking: booking,
      isDisabled: booking.isOutsideClaimWindow,
    };
  });

  return (
    <div className="max-w-3xl mx-auto p-4 mt-5 bg-white shadow-md rounded-lg">
      <h1 className="text-xl header__center font-semibold text-center mb-3">
        Submit a Claim
      </h1>
      <p className="text-sm text-gray-700 text-center mb-6">
        If you need to make a claim for a completed rental, please select the
        booking, describe the issue, and enter the amount (up to €
        {MAX_CLAIM_EUROS}). Our guarantee covers up to €{MAX_CLAIM_EUROS} for
        each rental.
      </p>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Booking Selector */}
        <div>
          <label className="block text-sm font-semibold text-gray-700 mb-2">
            Select Booking
          </label>
          {loading ? (
            <p>Loading bookings...</p>
          ) : bookings.length > 0 ? (
            <Select
              options={bookingOptions}
              components={{ Option }}
              value={selectedOption}
              onChange={handleBookingChange}
              isOptionDisabled={(option) => option.isDisabled}
              placeholder="Choose a booking"
              styles={{
                control: (provided) => ({
                  ...provided,
                  padding: "4px",
                  borderColor: "#cbd5e0",
                  boxShadow: "none",
                  "&:hover": { borderColor: "#a0aec0" },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? "#e6fffa"
                    : state.isFocused
                    ? "#f0fff4"
                    : null,
                  color: state.isDisabled ? "#a0aec0" : "#2d3748",
                  cursor: state.isDisabled ? "not-allowed" : "default",
                }),
              }}
            />
          ) : (
            <p>No bookings found.</p>
          )}
        </div>

        {/* Issue Description */}
        <div>
          <label className="block text-sm font-semibold text-gray-700 mb-1">
            Describe the Issue
          </label>
          <textarea
            id="issueDescription"
            value={issueDescription}
            onChange={(e) => setIssueDescription(e.target.value)}
            rows="4"
            className="w-full p-2 border border-gray-300 rounded-md focus:border-teal-600 focus:border-2 outline-none text-sm"
            placeholder="Please provide details about the issue."
          />
        </div>

        {/* Requested Amount */}
        <div>
          <div className="flex items-center gap-2 text-gray-700">
            <p className="text-sm font-semibold">Requested Amount (€)</p>
            <p className="text-sm font-semibold">
              Max claim: €{MAX_CLAIM_EUROS}
            </p>
          </div>
          <input
            type="number"
            id="requestedAmount"
            value={requestedAmount}
            onChange={(e) => setRequestedAmount(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:border-teal-600 focus:border-2 outline-none text-sm"
            placeholder="Enter amount"
            min="0"
            max={MAX_CLAIM_EUROS}
            step="0.01"
          />
        </div>

        {/* File Uploads */}
        <div>
          <label className="block text-sm font-semibold text-gray-700 mb-2">
            Upload Required Documents
          </label>
          <ul className="list-disc list-inside text-sm text-gray-700 mb-4">
            <li>
              <strong>Photos/Videos:</strong> Time-stamped media showing the
              item's condition before and after the rental.
            </li>
            <li>
              <strong>Police Report:</strong> In case of theft, please provide a
              police report.
            </li>
            <li>
              <strong>Repair Estimate:</strong> If damaged, include an estimate
              from a professional repair service.
            </li>
          </ul>
          <div className="flex justify-center md:justify-start">
            <button
              type="button"
              onClick={openUploadWidget}
              className={`btn-primary px-4 py-2 ${
                !authUser || !selectedBooking
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              disabled={!authUser || !selectedBooking}
            >
              Upload Documents
            </button>
          </div>
          <p className="text-xs text-gray-500 mt-1">
            You can upload up to 5 files. Accepted formats: images, videos,
            PDFs.
          </p>
          {imageFiles.length > 0 && (
            <div className="mt-4 grid grid-cols-2 md:grid-cols-3 gap-4">
              {imageFiles.map((imageFile, index) => (
                <div key={index} className="relative">
                  {["jpg", "jpeg", "png"].includes(imageFile.format) ? (
                    <img
                      src={imageFile.secure_url}
                      alt={`Uploaded file ${index + 1}`}
                      className="w-full h-32 object-cover rounded-md"
                    />
                  ) : (
                    <div className="w-full h-32 bg-gray-200 rounded-md flex justify-center items-center">
                      <p className="text-sm px-5">
                        {imageFile.original_filename}
                      </p>
                    </div>
                  )}
                  <button
                    type="button"
                    onClick={() => removeImage(index)}
                    className="absolute top-1 right-1 bg-red-500 text-white rounded-full px-2 py-1 hover:font-bold"
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <button
            type="submit"
            className={`px-8 py-2 ${
              parseFloat(requestedAmount) > MAX_CLAIM_EUROS ||
              !requestedAmount ||
              submittingClaim
                ? "btn-disabled cursor-not-allowed"
                : "btn-primary"
            }`}
            disabled={
              parseFloat(requestedAmount) > MAX_CLAIM_EUROS ||
              submittingClaim ||
              !requestedAmount
            }
          >
            {submittingClaim ? (
              <BeatLoader color="white" size={10} />
            ) : (
              "Submit Claim"
            )}
          </button>
        </div>
        <div>
          <p className="text-xs text-gray-700 mt-2">
            If you have any questions or concerns with the claim process, email
            us at <span className="font-semibold">support@kitshare.ie</span>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Claim;
