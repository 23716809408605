import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase.config";
import { truncate } from "lodash";
import formatCurrency from "../../utils/formatCurrency";

const OtherItemsOwnedBy = ({ itemLenderDetails, currentListingId }) => {
  const [userHasOtherItems, setUserHasOtherItems] = useState(false);
  const [otherItems, setOtherItems] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      itemLenderDetails.ownedListings &&
      itemLenderDetails.ownedListings.length > 1
    ) {
      setUserHasOtherItems(true);
      getOtherItems(); // Fetch other items when component mounts and when ownedListings changes
    } else {
      setUserHasOtherItems(false);
    }
  }, [itemLenderDetails.ownedListings]);

  const getOtherItems = async () => {
    if (!itemLenderDetails || !itemLenderDetails.ownedListings) {
      setOtherItems([]);
      return;
    }

    // Exclude the current listing ID
    const otherListingIds = itemLenderDetails.ownedListings.filter(
      (id) => id !== currentListingId
    );

    // Limit to 8 items
    const limitedListingIds = otherListingIds.slice(0, 8);

    const items = await Promise.all(
      limitedListingIds.map(async (listingId) => {
        const listingRef = doc(db, "listings", listingId);
        const listingSnap = await getDoc(listingRef);
        if (listingSnap.exists()) {
          return { id: listingId, ...listingSnap.data() };
        } else {
          console.error(`Listing with ID ${listingId} not found`);
          return null;
        }
      })
    );

    // Filter out any null results (in case a listing wasn't found)
    const validItems = items.filter((item) => item !== null);

    setOtherItems(validItems);
  };

  return (
    <>
      {userHasOtherItems && (
        <div className="my-5 mb-5 px-5 bg-gray-50 py-5">
          <p className="text-center text-gray-700 font-semibold text-lg md:pb-3 md:header__center">
            Other items owned by {itemLenderDetails.username}
          </p>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 mt-4">
            {otherItems.map((item) => (
              <div
                key={item.id}
                className="p-2 bg-white border rounded-md shadow hover:cursor-pointer hover:shadow-lg duration-300"
                onClick={() => navigate(`/listings/${item.id}`)}
              >
                <div className="flex justify-center items-center overflow-hidden">
                  <img
                    src={item.imageURLs[0]}
                    alt={item.title}
                    className="object-contain h-36 w-full" // Maintain aspect ratio and prevent distortion
                  />
                </div>
                <div className="p-1">
                  {/* <p className="font-bold text-center rounded-md p-1 my-2"> */}
                  <p className="bg-teal-600 font-semibold text-white text-center rounded-md p-1 my-2">
                    {truncate(item.title, { length: 30 })}
                  </p>
                  <p className="text-lg text-center mt-3 font-bold text-emerald-600">
                    €{formatCurrency(item.dailyRateCents)} / day
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default OtherItemsOwnedBy;
