import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEuro,
  faShareAlt,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import "../css/HowItWorks.css";

const HowItWorks = () => {
  return (
    <div className="py-4 sm:py-5 mb-10 md:py-10">
      <h1 className="text-xl md:text-3xl font-semibold text-gray-700 text-center mb-8 md:mb-10">
        It's as easy as...
      </h1>

      <div className="flex flex-col md:flex-row justify-center align-center md:items-stretch gap-10 xl:gap-20">
        <div className="card-wrap shadow-md hover:shadow-lg" data-step="1">
          <div className="card-header three">
            <FontAwesomeIcon icon={faUserLock} />
          </div>
          <div className="card-content">
            <h1 className="font-semibold text-teal-700 text-center uppercase text-lg md:text-xl my-5">
              Sign Up in Minutes
            </h1>
            <p className="text-sm md:text-base text-center mb-5">
              Join our community by providing just a few details. Start renting
              or listing in no time.
            </p>
          </div>
        </div>

        <div className="card-wrap shadow-md hover:shadow-lg" data-step="2">
          <div className="card-header three">
            <FontAwesomeIcon icon={faShareAlt} />
          </div>
          <div className="card-content">
            <h1 className="font-semibold text-teal-700 text-center uppercase text-lg md:text-xl my-5">
              List or Rent
            </h1>
            <p className="text-sm md:text-base text-center mb-5">
              List your items to earn extra income or rent what you need with
              just a few clicks.
            </p>
          </div>
        </div>

        <div className="card-wrap shadow-md hover:shadow-lg" data-step="3">
          <div className="card-header three">
            <FontAwesomeIcon icon={faEuro} />
          </div>
          <div className="card-content">
            <h1 className="font-semibold text-teal-700 text-center uppercase text-lg md:text-xl my-5">
              Get Paid
            </h1>
            <p className="text-sm md:text-base text-center mb-5">
              Manage your earnings securely with Stripe. Simple, secure, and
              efficient payments.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
